import React from 'react';
import { Row, Col } from 'antd';
import formatCumulative from '../helpers/formatCumulative';
import setInverted from '../helpers/setInverted';

const DashboardFooter = (props) => {
  const { abatedList } = props;

  const loadCols = props.loads.map((load) => {
    const inverted = setInverted(props.energySystemId, load, props.energySystems);

    let selectedLoad = load.split('_');
    selectedLoad.shift();
    selectedLoad = selectedLoad.join('_');

    let emissions_type = 'grid';
    for (let system of props.energySystems) {
      for (let device of system.deviceMap) {
        if (device.label === selectedLoad) {
          emissions_type = device.emissions_type;
        }
      }
    }

    return (
      <Col key={load} onClick={() => props.setLoad(load)}>
        <span
          style={
            props.load === load
              ? {
                  padding: 8,
                  border: '1px solid rgba(255, 255, 255, 1)',
                  borderRadius: 5,
                  textShadow: '0 0 2px rgba(255, 255, 255, 0.3)',
                  boxShadow: '0 0 4px rgba(255, 255, 255, 0.3)',
                }
              : {}
          }
        >
          {load.split('_')[1]}:{' '}
          {emissions_type === 'solar' && props.display === 'emissionsData'
            ? `${abatedList[props.period]} ${props.period === 'live' ? 'kgCO2e/h' : ' kgCO2e'}`
            : formatCumulative(
                Math.abs(props.cumulativeValues[props.period][props.display][load]),
                props.display,
                props.period === 'live',
                inverted
              )}
          {/* {formatCumulative(
            Math.abs(props.cumulativeValues[props.period][props.display][load]),
            props.display,
            props.period === 'live',
            inverted
          )} */}
        </span>
      </Col>
    );
  });

  return (
    <Row style={{ width: '100%', backgroundColor: '#1890ff' }}>
      <Col flex="auto" className="period-selector" align="middle" justify="space-around">
        <Row justify="center" gutter={30}>
          {loadCols}
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardFooter;
