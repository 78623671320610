import React from 'react';
import { Table } from 'antd';

const columns = [
  { title: 'Farm Name', dataIndex: 'siteName', key: 'siteName' },
  { title: 'Region', dataIndex: 'region', key: 'region' },
  { title: 'Postcode', dataIndex: 'sitePostcode', key: 'postcode' },
  {
    title: 'Dashboard',
    dataIndex: 'dashboard',
    key: 'dashboard',
    align: 'center',
  },
  {
    title: 'Export Data',
    dataIndex: 'exportLinks',
    key: 'exportLinks',
  },
  {
    title: 'Status',
    dataIndex: 'deviceStatus',
    key: 'deviceStatus',
  },
];

export default function ParticipantsTable(props) {
  return (
    <Table
      expandedRowRender={(record) => (
        <p style={{ margin: 0 }}>
          {record.legalName} {record.abn ? 'ABN:' : ''} {record.abn}
        </p>
      )}
      rowKey={(record) => record.id}
      dataSource={props.participants}
      pagination={false}
      columns={columns}
      loading={props.loading}
      bordered
    />
  );
}
