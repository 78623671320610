// import React, { useState, useContext } from 'react';
import React, { useContext } from 'react';
import {store } from '../context/AppContext';
import './ApplicationLayout.css';
import {Layout, Row } from "antd";
import SideBar from "./components/SideBar";
import SliderTrigger from "./components/SliderTrigger";
import { useLocation } from 'react-router-dom';

const { Content, Sider, Header, Footer } = Layout;

export default function ApplicationLayout(props) {
    const location = useLocation();
    const context = useContext(store);
    const { state, dispatch } = context;

    // const [title, setTitle] = useState("");

    const siderCollapse = (collapsed) => { 
        dispatch({type: "TRIGGERED", data: collapsed});
    };

    return (
        <Layout>
            {(location.pathname !== '/customer') &&
                <Sider
                    className="slider"
                    breakpoint="lg"
                    collapsedWidth="0"
                    collapsed={state.triggered}
                    trigger={null}
                    collapsible
                >
                    <SideBar
                        siderCollapsed={state.triggered}
                        pathname={location.pathname}
                        siderCollapse={siderCollapse}
                    />
                </Sider>
            }
            <Layout
                style={{
                    minHeight: "100vh",
                    minWidth: window.innerWidth < 768 ? "100%" : 0,
                    backgroundColor: ['/', '/dashboard'].includes(location.pathname) ? "#1890ff" : "#fff"
                }}
            >
                {
                    !['/', '/dashboard', '/customer'].includes(location.pathname) && (
                        <Header className="default-header" style={{paddingLeft:0}}>
                            <SliderTrigger sliderTriggered={state.triggered}/>
                            {/* <span className="title">
                                {title}
                            </span> */}
                        </Header>
                    )
                }
                <Content>
                    {props.children}
                </Content>
                {
                    !['/', '/dashboard'].includes(location.pathname) && (
                        <Footer className="footer">
                            <Row align="bottom" justify="space-around">
                                <span>All Rights Reserved 2018-{new Date().getFullYear()} &copy; Fluxpower Pty Ltd ABN: 48625419249</span>
                            </Row>
                        </Footer>
                    )
                }
            </Layout>
        </Layout>
    );
}
