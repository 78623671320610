import React, {useContext} from "react";
import { List, Avatar, message } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { API } from 'aws-amplify';
import { store } from '../../context/AppContext';

export default function CreateItem(props) {
    const context = useContext(store);
    const { state, dispatch } = context;
    const authData = JSON.parse(localStorage.getItem("authData"));

    const onAccountClick = () => {
        dispatch({type: 'CUSTOMER', data: props.customer});
    }

    const acceptInvite = async () => {
        const response = await new Promise((resolve, reject) => {
            const apiName = 'userapi';
            const path = '/user/update';
            const body = {
                userId: state.userId,
                customers: props.customers.concat(props.customer.id),
                env: process.env.REACT_APP_ENV
            };

            API
                .put(apiName, path, {body})
                .then(response => resolve(response))
                .catch(error => { console.log('error', error) });
        });

        if (response.code === 200) {
            await new Promise((resolve, reject) => {
                const apiName = 'invitationapi';
                const path = '/invite/update';
                const body = {
                    email: authData.email,
                    customerId: props.customer.id,
                    env: process.env.REACT_APP_ENV
                };

                API
                    .put(apiName, path, {body})
                    .then(response => resolve(response))
                    .catch(error => { console.log('error', error) });
            });

            dispatch({type: 'CUSTOMER', data: props.customer});
        }
        else {
            message.error('Failed to accept invitation, please try again later.');
        }
    }

    return (
        <List.Item
            onClick={props.type === 'invite' ? acceptInvite : onAccountClick}
            style={{
                cursor: "pointer",
                backgroundColor: "#fff",
                padding: "10px 0 10px 18px",
                borderRadius: "18px",
                marginBottom: "15px",
                boxShadow: "0 0px 8px 4px rgba(0, 0, 0, 0.05)",
                width: 284
            }}
            >
            <List.Item.Meta
                style={{alignItems: "center"}}
                avatar={props.customer.avatar ? <Avatar shape="square" size={40} src={props.customer.avatar} /> : <Avatar shape="square" size={40} icon={<UserOutlined />} />}
                title={<span>{props.customer.siteName}</span>}
                description={props.customer.legalName}
            />
        </List.Item>
    )
}
