import { API } from 'aws-amplify';

export default async function (clientId, groupName) {
  const promise = new Promise((resolve, reject) => {
    const apiName = 'customerapi';
    const path = `/customer/all?customer_id=${clientId}&group_name=${groupName}&env=${process.env.REACT_APP_ENV}`;

    API.get(apiName, path)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error', error);
      });
  });

  return await promise;
}
