// Fetch all unique energy systems (for now because we can't do it any more efficiently)

import API from '@aws-amplify/api';
import aws from 'aws-sdk';
import { GetCustomerIds } from '../../navigation/helpers/getCustomerIds';

aws.config.update({
  credentials: {
    accessKeyId: 'AKIARNUIJJIKRD2BR54P',
    secretAccessKey: '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',
  },
  region: 'ap-southeast-2',
});
const sns = new aws.SNS();

// Now with our complete list of unique devices linked to this customer

// Check last updated timestamp, if reasonable, update device

// Get lastest wattwatchers energy tick ??? do we want to do this??
// I think what makes more sense, is just ping a lambda with the devices list and let that do it for us
// via SNS

// Update Energy Systems for all Devices

export async function UpdateDevices() {
  let customerIds = JSON.parse(localStorage.getItem('customerIds'));

  try {
    if (!customerIds || customerIds.length === 0) {
      await GetCustomerIds();
      customerIds = JSON.parse(localStorage.getItem('customerIds'));

      if (!customerIds) {
        throw new Error('unable to access local storage');
      }
    }

    let uniqueDevices = [];

    console.log(customerIds[0]);
    for (let customer_id of customerIds) {
      // Fetch energy systems, test fetching all energy systems first
      // if we get a hit, on an of the customers, then we can just move forward
      // as we have all devices anyway
      try {
        // console.log(customer.id);
        let response = await API.get(
          'energysystemapi',
          `/energysystem/all?customer_id=${customer_id}&env=${process.env.REACT_APP_ENV}`
        );
        if (response.code === 200) {
          uniqueDevices = JSON.parse(response.energySystems);
          break;
        }
      } catch (error) {
        console.log(error);
        continue;
      }
    }

    // Otherwise, proceed as planned
    if (uniqueDevices.length === 0) {
      for (let customer_id of customerIds) {
        let devices = [];
        try {
          const response = await API.get(
            'energysystemapi',
            `/energysystem?customer_id=${customer_id}&env=${process.env.REACT_APP_ENV}`
          );

          if (response.code === 200) {
            devices = JSON.parse(response.energySystem);
          }
        } catch (error) {
          console.log(error);
          continue;
        }

        for (let device of devices) {
          let isUnique = true;
          for (let unique of uniqueDevices) {
            if (unique.id === device.id) {
              isUnique = false;
              break;
            }
          }
          if (isUnique) {
            uniqueDevices.push(device);
          }
        }
      }
    }

    const TopicArn =
      process.env.REACT_APP_ENV === 'production'
        ? 'arn:aws:sns:ap-southeast-2:097996327445:update_energy_systems_on_trigger'
        : 'arn:aws:sns:ap-southeast-2:097996327445:update_energy_systems_on_trigger-dev';

    // Ping SNS service
    const publishTextPromise = sns
      .publish({
        TopicArn: TopicArn,
        Message: JSON.stringify(uniqueDevices),
      })
      .promise();

    await publishTextPromise
      .then(function (data) {})
      .catch(function (err) {
        console.error(err, err.stack);
      });
  } catch (error) {
    // console.log(error);
    console.log('Update of devices failed with error');
    console.log(error.message);
  }
}
