import { API } from 'aws-amplify';

export default async function getUser() {
  const cognitoUser = localStorage.getItem('cognitoUser');

  return await new Promise((resolve, reject) => {
    const apiName = 'userapi';
    const path = `/user?id=${cognitoUser}&env=${process.env.REACT_APP_ENV}`;

    API.get(apiName, path)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error', error);
      });
  });
}
