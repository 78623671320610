import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const deviceOptions = energySystems => {
    return energySystems.map((energySystem) => {
        const deviceId = energySystem.identifierNumber;

        return <Option key={deviceId} value={deviceId}>{deviceId}</Option>
    });
}

export const exportOptions = [
    // {label: 'live sheet', value: 'liveSheet'},
    // {label: 'raw data CSV', value: 'exportRaw'},
    // {label: 'transformed data CSV', value: 'exportTransformed'}
    {label: 'raw data', value: 'exportRaw'},
    {label: 'transformed data', value: 'exportTransformed'}
];
