export default function calculateCumulative(energyAccumulator, energyData, timestamp) {
  let accumulators = {
    live: {
      energyData: {},
      dollarData: {},
      emissionsData: {},
    },
    day: {
      energyData: {},
      dollarData: {},
      emissionsData: {},
    },
    week: {
      energyData: {},
      dollarData: {},
      emissionsData: {},
    },
    month: {
      energyData: {},
      dollarData: {},
      emissionsData: {},
    },
    year: {
      energyData: {},
      dollarData: {},
      emissionsData: {},
    },
  };

  for (let energySystemId in energyData) {
    let energySystemData = energyData[energySystemId];
    if (!!energySystemData.shortEnergy?.length) {
      let shortEnergies = energySystemData.shortEnergy;
      shortEnergies = shortEnergies.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

      let shortAccumulators = { energyData: {}, dollarData: {}, emissionsData: {} };

      const newestData = shortEnergies[shortEnergies.length - 1];

      for (let display in shortAccumulators) {
        for (let monthEnergyData of energyAccumulator[energySystemId].month[0].energyData) {
          shortAccumulators[display][energySystemId + '_' + monthEnergyData.label] =
            (newestData[display].find((item) => item.label === monthEnergyData.label)?.value || 0) /
            (newestData.duration / (60 * 60));
        }
      }

      accumulators.live.energyData = {
        ...accumulators.live.energyData,
        ...shortAccumulators.energyData,
      };
      accumulators.live.dollarData = {
        ...accumulators.live.dollarData,
        ...shortAccumulators.dollarData,
      };
      accumulators.live.emissionsData = {
        ...accumulators.live.emissionsData,
        ...shortAccumulators.emissionsData,
      };
    } else {
      let zeroAccumulators = {};

      for (let monthEnergyData of energyAccumulator[energySystemId].month[0].energyData) {
        zeroAccumulators[energySystemId + '_' + monthEnergyData.label] = 0;
      }

      accumulators.live.energyData = { ...accumulators.live.energyData, ...zeroAccumulators };
      accumulators.live.dollarData = { ...accumulators.live.dollarData, ...zeroAccumulators };
      accumulators.live.emissionsData = { ...accumulators.live.emissionsData, ...zeroAccumulators };
    }
  }

  for (let energySystemId in energyAccumulator) {
    let energyAccumulatorRecord = energyAccumulator[energySystemId];

    if (energyAccumulatorRecord.minute) {
      const offline = timestamp <= Math.floor(Date.now() / 1000 - 24 * 60 * 60);

      let dayAccumulators = calculateAccumulators(
        [].concat.apply(
          [],
          energyAccumulatorRecord.minute.map((item) => (offline ? 0 : item.energyData))
        ),
        [].concat.apply(
          [],
          energyAccumulatorRecord.minute.map((item) => (offline ? 0 : item.dollarData))
        ),
        [].concat.apply(
          [],
          energyAccumulatorRecord.minute.map((item) => (offline ? 0 : item.emissionsData))
        ),
        energySystemId
      );
      accumulators.day.energyData = {
        ...accumulators.day.energyData,
        ...dayAccumulators.energyData,
      };
      accumulators.day.dollarData = {
        ...accumulators.day.dollarData,
        ...dayAccumulators.dollarData,
      };
      accumulators.day.emissionsData = {
        ...accumulators.day.emissionsData,
        ...dayAccumulators.emissionsData,
      };
    }

    if (energyAccumulatorRecord.hour) {
      const offline = timestamp <= Math.floor(Date.now() / 1000 - 7 * 24 * 60 * 60);
      let weekAccumulators = calculateAccumulators(
        [].concat.apply(
          [],
          energyAccumulatorRecord.hour.map((item) => (offline ? 0 : item.energyData))
        ),
        [].concat.apply(
          [],
          energyAccumulatorRecord.hour.map((item) => (offline ? 0 : item.dollarData))
        ),
        [].concat.apply(
          [],
          energyAccumulatorRecord.hour.map((item) => (offline ? 0 : item.emissionsData))
        ),
        energySystemId
      );
      accumulators.week.energyData = {
        ...accumulators.week.energyData,
        ...weekAccumulators.energyData,
      };
      accumulators.week.dollarData = {
        ...accumulators.week.dollarData,
        ...weekAccumulators.dollarData,
      };
      accumulators.week.emissionsData = {
        ...accumulators.week.emissionsData,
        ...weekAccumulators.emissionsData,
      };
    }

    let monthDate = new Date();
    monthDate.setDate(monthDate.getDate() - 30);
    // eslint-disable-next-line array-callback-return
    const monthData = energyAccumulatorRecord.day.filter((data) => {
      if (data.date) {
        let arrayDate = data.date.split('-');
        if (arrayDate[1].length === 1) arrayDate[1] = '0' + arrayDate[1];
        if (arrayDate[2].length === 1) arrayDate[2] = '0' + arrayDate[2];

        let formatedDate = arrayDate.join('-');
        return (
          formatedDate >
          `${monthDate.getFullYear()}-${('0' + (monthDate.getMonth() + 1)).slice(-2)}-${(
            '0' + monthDate.getDate()
          ).slice(-2)}`
        );
      }
    });

    let monthAccumulators = calculateAccumulators(
      [].concat.apply(
        [],
        monthData.map((item) => item.energyData)
      ),
      [].concat.apply(
        [],
        monthData.map((item) => item.dollarData)
      ),
      [].concat.apply(
        [],
        monthData.map((item) => item.emissionsData)
      ),
      energySystemId
    );
    accumulators.month.energyData = {
      ...accumulators.month.energyData,
      ...monthAccumulators.energyData,
    };
    accumulators.month.dollarData = {
      ...accumulators.month.dollarData,
      ...monthAccumulators.dollarData,
    };
    accumulators.month.emissionsData = {
      ...accumulators.month.emissionsData,
      ...monthAccumulators.emissionsData,
    };
    let yearDate = new Date();
    yearDate.setMonth(yearDate.getMonth() - 12);
    const yearData = energyAccumulatorRecord.month.filter((data) => {
      let formatedDate = data.date.length === 6 ? data.date.slice(0, 5) + '0' + data.date.slice(5) : data.date;
      return formatedDate > `${yearDate.getFullYear()}-${('0' + (yearDate.getMonth() + 1)).slice(-2)}`;
    });

    let yearAccumulators = calculateAccumulators(
      [].concat.apply(
        [],
        yearData.map((item) => item.energyData)
      ),
      [].concat.apply(
        [],
        yearData.map((item) => item.dollarData)
      ),
      [].concat.apply(
        [],
        yearData.map((item) => item.emissionsData)
      ),
      energySystemId
    );
    accumulators.year.energyData = {
      ...accumulators.year.energyData,
      ...yearAccumulators.energyData,
    };
    accumulators.year.dollarData = {
      ...accumulators.year.dollarData,
      ...yearAccumulators.dollarData,
    };
    accumulators.year.emissionsData = {
      ...accumulators.year.emissionsData,
      ...yearAccumulators.emissionsData,
    };
  }

  return accumulators;
}

const calculateAccumulators = (energyData, dollarData, emissionsData, deviceId, old = false) => {
  let energyAccumulator = {};
  let dollarAccumulator = {};
  let emissionsAccumulator = {};

  for (let key of [...Array.from(new Set(dollarData.map((item) => item.label)))]) {
    energyAccumulator[deviceId + '_' + key] = old ? 0 : Math.abs(sumData(energyData, key));
    dollarAccumulator[deviceId + '_' + key] = old ? 0 : Math.abs(sumData(dollarData, key));
    emissionsAccumulator[deviceId + '_' + key] = old ? 0 : Math.abs(sumData(emissionsData, key));
  }

  return {
    energyData: energyAccumulator,
    dollarData: dollarAccumulator,
    emissionsData: emissionsAccumulator,
  };
};

const sumData = (data, key) => {
  return data.reduce((a, b) => (b.label === key ? a + (b.value || 0) : a), 0);
};
