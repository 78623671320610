import { GetEnergySystemIdAndLabel } from './dataToGraph';

export default function shortDataToGraph(
  energyData,
  energySystemId,
  category,
  option,
  duration,
  inverted,
  energySystems
) {
  let [grid_system_id, grid_category] = GetEnergySystemIdAndLabel(energySystems, 'grid');
  let [solar_system_id, solar_category] = GetEnergySystemIdAndLabel(energySystems, 'solar');

  console.log('Energy Data');
  console.log(energyData);

  console.log('Grid System Id, ', grid_system_id);
  console.log('Grid Category, ', grid_category);
  console.log('Solar System Id, ', solar_system_id);
  console.log('Solar Category, ', solar_category);
  console.log(category);

  let selectedLoad = category.split('_');
  selectedLoad.shift();
  selectedLoad = selectedLoad.join('_');

  let selected_load_emissions_type = 'grid';
  for (let system of energySystems) {
    for (let device of system.deviceMap) {
      if (device.label === selectedLoad) {
        selected_load_emissions_type = device.emissions_type;
      }
    }
  }

  let energies = energyData[energySystemId].shortEnergy;

  let latestTimestamp = Math.max.apply(
    Math,
    energies.map((item) => item.timestamp)
  );
  let currentTimestamp = Math.round(Date.now() / 1000);

  if (latestTimestamp + 15 * 60 < currentTimestamp) {
    let fiveMinutesReminder = currentTimestamp % 300;
    currentTimestamp = currentTimestamp - fiveMinutesReminder - 300;

    let reminder = (currentTimestamp - latestTimestamp) % duration;
    latestTimestamp = currentTimestamp - reminder;
  }

  let values = [];
  let accumulator = 0;

  let firstTimestamp = latestTimestamp - (3600 / duration - 1) * duration;

  energies = energies.filter((data) => data.timestamp >= firstTimestamp);

  let allEnergies = Object.keys(energyData).map((key) => {
    return energyData[key].shortEnergy.filter((data) => data.timestamp >= firstTimestamp);
  });

  for (let i = 0; i < 3600 / duration; i++) {
    let energy = energies.find((item) => item.timestamp === firstTimestamp + i * duration);

    if (energy) {
      let energyValue = energy[option].find((item) => energySystemId + '_' + item.label === category);
      let value = energyValue ? (inverted ? energyValue.value * -1 : energyValue.value) : 0;
      value = Math.abs(value) / (duration / (60 * 60)); //I Math.abs'd it because idk why it wasn't working properly int he first place and I want to be free
      accumulator += value;

      let gridIntensity = GenerateGridIntensity(energy, grid_category, energySystemId);

      let totalSolar = 0;
      let totalEnergy = 0;

      for (let energies_item of allEnergies) {
        let anEnergy = energies_item.find((item) => item.timestamp === firstTimestamp + i * duration);
        // console.log("Energy Item");
        // console.log(energies_item)
        if (anEnergy) {
          for (let item of anEnergy['energyData']) {
            if (!item) {
              break;
            }
            // figure out if energy is solar or not
            let isSolar = false;

            for (let system of energySystems) {
              for (let mapItem of system.deviceMap) {
                if (item.label === mapItem.label && mapItem.emissions_type === 'solar') {
                  isSolar = true;
                  break;
                }
              }
            }

            if (isSolar) {
              totalSolar += Math.abs(item.value);
              totalEnergy += Math.abs(item.value);
            } else {
              totalEnergy += Math.abs(item.value);
            }
          }
        }
      }

      // console.log('Total Solar: ', totalSolar);
      // console.log('Total Energy: ', totalEnergy);

      let emissionsIntensityProportion = Math.round(10000 * ((totalEnergy - totalSolar) / totalEnergy)) / 100;

      // console.log('Emissions Intensity Proportion: ', emissionsIntensityProportion);
      let abated = 0;

      if (selected_load_emissions_type === 'solar') {
        let energyValue = energy.energyData.find((x) => x.label === selectedLoad).value ?? 0;
        abated = Math.abs(energyValue * gridIntensity);
      }

      abated = Math.round(abated * 10000) / 10000;

      if (selected_load_emissions_type === 'solar') {
        values.push({
          time: i,
          amount: abated,
          timestamp: energy.timestamp,
          accumulator: accumulator,
          duration: duration,
          gridIntensity: gridIntensity,
          emissionsIntensityProportion: emissionsIntensityProportion,
          abated: solar_category ? abated : null,
        });
      } else {
        values.push({
          time: i,
          amount: value,
          timestamp: energy.timestamp,
          accumulator: accumulator,
          duration: duration,
          gridIntensity: gridIntensity,
          emissionsIntensityProportion: emissionsIntensityProportion,
          abated: solar_category ? abated : null,
        });
      }
    } else {
      values.push({
        time: i,
        amount: 0,
        timestamp: firstTimestamp + i * duration,
        accumulator: accumulator,
        duration: duration,
        gridIntensity: 0,
        emissionsIntensityProportion: 0,
        abated: solar_category ? 0 : null,
      });
    }
  }

  return values;
}

function GenerateGridIntensity(energy, grid_category) {
  if (energy) {
    let energy_use = 0;
    let emissions_data = 0;
    let proportion = 0;
    energy_use = energy['energyData']?.find((item) => item.label === grid_category)?.value;
    emissions_data = energy['emissionsData']?.find((item) => item.label === grid_category)?.value;

    if (energy_use && emissions_data && energy_use !== 0) {
      proportion = emissions_data / energy_use;
    }

    return Math.round(proportion * 1000) / 1000;
  } else {
    return 0;
  }
}

// function GenerateAbated2(gridIntensity) {
//   energy_use = gridAccumulator['energyData'].find((item) => item.label === grid_category)?.value;
//   let emissions_data = gridAccumulator['emissionsData'].find((item) => {
//     // console.log('Item Label');
//     // console.log(item.label);

//     // console.log("Grid_category");
//     // console.log(grid_category)
//     return item.label === grid_category;
//   })?.value;

//   // console.log('Energy Use');
//   // console.log(energy_use);

//   // console.log('Emissions_data');
//   // console.log(emissions_data);

//   if (!energy_use || energy_use === 0) {
//     emissions_index = 0;
//   } else {
//     emissions_index = Math.abs(emissions_data / energy_use);
//   }

//   solar_energy_use = solarAccumulator['energyData'].find((item) => item.label === solar_category)?.value;

//   // console.log('Solar Energy Use');
//   // console.log(solar_energy_use);

//   // console.log('Emissions_index');
//   // console.log(emissions_index);

//   if (solar_energy_use) {
//     abated = Math.abs(solar_energy_use) * emissions_index;
//   }
// }

// function GenerateAbated(
//   energyAccumulators,
//   accumulator_type,
//   timeString,
//   grid_category,
//   solar_category,
//   grid_system_id,
//   solar_system_id,
//   latestEnergyTimestamp,
//   energySystemId,
//   finalAccumulator,
//   index
// ) {
//   let emissions_index = 0;
//   let energy_use = 0;
//   let solar_energy_use = 0;
//   let abated = 0;

//   if (!solar_category) {
//     return 0;
//   }

//   let gridAccumulator = null;
//   let solarAccumulator = null;

//   if (accumulator_type === 'minute') {
//     if (grid_system_id === energySystemId) {
//       gridAccumulator = finalAccumulator[index];
//     } else {
//       const lastDay = new Date(latestEnergyTimestamp * 1000);
//       lastDay.setHours(0, 0, 0, 0);

//       const minuteId = Math.floor((latestEnergyTimestamp * 1000 - lastDay.getTime()) / 300000);

//       let finalMinuteGridAccumulator;

//       let tempAccumulator = energyAccumulators[grid_system_id];

//       if (tempAccumulator[accumulator_type]) {
//         const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
//         finalMinuteGridAccumulator = orderedAccumulator
//           .slice(minuteId + 2, orderedAccumulator.length)
//           .concat(orderedAccumulator.slice(0, minuteId + 2));
//       } else {
//         finalMinuteGridAccumulator = [];
//       }

//       gridAccumulator = finalMinuteGridAccumulator[index];
//     }

//     if (solar_system_id === energySystemId) {
//       solarAccumulator = finalAccumulator[index];
//     } else {
//       const lastDay = new Date(latestEnergyTimestamp * 1000);
//       lastDay.setHours(0, 0, 0, 0);

//       const minuteId = Math.floor((latestEnergyTimestamp * 1000 - lastDay.getTime()) / 300000);

//       let finalMinuteSolarAccumulator;

//       let tempAccumulator = energyAccumulators[solar_system_id];

//       if (tempAccumulator[accumulator_type]) {
//         const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
//         finalMinuteSolarAccumulator = orderedAccumulator
//           .slice(minuteId + 2, orderedAccumulator.length)
//           .concat(orderedAccumulator.slice(0, minuteId + 2));
//       } else {
//         finalMinuteSolarAccumulator = [];
//       }

//       solarAccumulator = finalMinuteSolarAccumulator[index];
//     }
//   } else if (accumulator_type === 'hour') {
//     if (grid_system_id === energySystemId) {
//       gridAccumulator = finalAccumulator[index];
//     } else {
//       const latestSunday = new Date(latestEnergyTimestamp * 1000);
//       latestSunday.setDate(latestSunday.getDate() - latestSunday.getDay());
//       latestSunday.setHours(0, 0, 0, 0);

//       const hourId = Math.floor((latestEnergyTimestamp * 1000 - latestSunday.getTime()) / 3600000);

//       let finalHourGridAccumulator;

//       let tempAccumulator = energyAccumulators[grid_system_id];
//       if (tempAccumulator[accumulator_type]) {
//         const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
//         finalHourGridAccumulator = orderedAccumulator
//           .slice(hourId + 1, orderedAccumulator.length)
//           .concat(orderedAccumulator.slice(0, hourId + 1));
//       } else {
//         finalHourGridAccumulator = [];
//       }

//       // console.log('Temp Accumulator');
//       // console.log(tempAccumulator);

//       gridAccumulator = finalHourGridAccumulator[index];
//       // console.log('Grid Accumulator');
//       // console.log(gridAccumulator);
//     }

//     if (solar_system_id === energySystemId) {
//       solarAccumulator = finalAccumulator[index];
//     } else {
//       const latestSunday = new Date(latestEnergyTimestamp * 1000);
//       latestSunday.setDate(latestSunday.getDate() - latestSunday.getDay());
//       latestSunday.setHours(0, 0, 0, 0);

//       const hourId = Math.floor((latestEnergyTimestamp * 1000 - latestSunday.getTime()) / 3600000);

//       let finalHourSolarAccumulator;

//       let tempAccumulator = energyAccumulators[grid_system_id];
//       if (tempAccumulator[accumulator_type]) {
//         const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
//         finalHourSolarAccumulator = orderedAccumulator
//           .slice(hourId + 1, orderedAccumulator.length)
//           .concat(orderedAccumulator.slice(0, hourId + 1));
//       } else {
//         finalHourSolarAccumulator = [];
//       }

//       // console.log('Temp Accumulator');
//       // console.log(tempAccumulator);

//       solarAccumulator = finalHourSolarAccumulator[index];

//       // console.log('Grid Accumulator');
//       // console.log(solarAccumulator);
//     }
//   } else {
//     gridAccumulator = energyAccumulators[grid_system_id][accumulator_type].find((record) => record.date === timeString);
//     solarAccumulator = energyAccumulators[solar_system_id][accumulator_type].find(
//       (record) => record.date === timeString
//     );
//   }

//   // console.log('Grid Accumulator');
//   // console.log(gridAccumulator);
//   // console.log('Solar Accumulator');
//   // console.log(solarAccumulator);

//   // console.log('Solar Category: ', solar_category);

//   if (gridAccumulator && solarAccumulator) {
//     energy_use = gridAccumulator['energyData'].find((item) => item.label === grid_category)?.value;

//     let emissions_data = gridAccumulator['emissionsData'].find((item) => {
//       // console.log('Item Label');
//       // console.log(item.label);

//       // console.log("Grid_category");
//       // console.log(grid_category)
//       return item.label === grid_category;
//     })?.value;

//     // console.log('Energy Use');
//     // console.log(energy_use);

//     // console.log('Emissions_data');
//     // console.log(emissions_data);

//     if (!energy_use || energy_use === 0) {
//       emissions_index = 0;
//     } else {
//       emissions_index = Math.abs(emissions_data / energy_use);
//     }

//     solar_energy_use = solarAccumulator['energyData'].find((item) => item.label === solar_category)?.value;

//     // console.log('Solar Energy Use');
//     // console.log(solar_energy_use);

//     // console.log('Emissions_index');
//     // console.log(emissions_index);

//     if (solar_energy_use) {
//       abated = Math.abs(solar_energy_use) * emissions_index;
//     }
//   }
//   // console.log('Abated: ', abated);

//   return abated;
// }
