export default function formatCumulative(cumulativeValue, display, live = false, inverted = false) {
  cumulativeValue = cumulativeValue || 0;
  // if (inverted) cumulativeValue = cumulativeValue * -1;

  const precision = cumulativeValue > 1000 ? 0 : live ? 4 : 2;
  const text = cumulativeValue.toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  if (display === 'dollarData') return '$' + text + (live ? '/h' : '');
  else if (display === 'emissionsData') return text + (live ? ' kgCO2e/h' : ' kgCO2e');
  else return text + (live ? ' kW' : ' kWh');
}
