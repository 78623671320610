import React, { Fragment } from 'react';
import SliderTrigger from '../../common/components/SliderTrigger';
import { Row, Col, Select, Switch, Radio, Space } from 'antd';
import '../DashboardScreen.css';
import formatCumulative from '../helpers/formatCumulative';
import setInverted from '../helpers/setInverted';

import { ThunderboltOutlined, DollarOutlined, CloudSyncOutlined, WarningOutlined } from '@ant-design/icons';

const { Option } = Select;

const DashboardHeader = (props) => {
  const { abatedList } = props;
  if (!props.periods)
    return (
      <Row style={{ width: '100%', backgroundColor: '#1890ff' }}>
        <Col xs={2} sm={1} style={{ textAlign: 'center' }}>
          <SliderTrigger sliderTriggered={true} />
        </Col>
      </Row>
    );

  const inverted = setInverted(props.energySystemId, props.load, props.energySystems);
  const mobile = window.innerWidth < 768;

  console.log('LOad');
  console.log(props.load);

  let selectedLoad = props.load.split('_');
  selectedLoad.shift();
  selectedLoad = selectedLoad.join('_');

  let emissions_type = 'grid';
  for (let system of props.energySystems) {
    for (let device of system.deviceMap) {
      if (device.label === selectedLoad) {
        emissions_type = device.emissions_type;
      }
    }
  }

  const loads = (
    <Col xs={16} sm={21} offset={0} className="period-selector">
      <Row justify="space-around" style={{ width: '100%' }}>
        {Object.keys(props.cumulativeValues).map((period) => {
          return (
            <Col
              key={period}
              xs={24}
              sm={4}
              onClick={() => {
                props.setPeriod(period);
              }}
              style={{
                textAlign: 'center',
              }}
            >
              <span
                style={
                  props.period === period
                    ? {
                        padding: 8,
                        border: '1px solid rgba(255, 255, 255, 1)',
                        borderRadius: 5,
                        textShadow: '0 0 2px rgba(255, 255, 255, 0.3)',
                        boxShadow: '0 0 4px rgba(255, 255, 255, 0.3)',
                      }
                    : {}
                }
              >
                {period}:{' '}
                {emissions_type === 'solar' && props.display === 'emissionsData'
                  ? `${abatedList[period]} ${period === 'live' ? 'kgCO2e/h' : ' kgCO2e'}`
                  : formatCumulative(
                      Math.abs(props.cumulativeValues[period][props.display][props.load]),
                      props.display,
                      period === 'live',
                      inverted
                    )}
                {/* {formatCumulative(
                  Math.abs(props.cumulativeValues[period][props.display][props.load]),
                  props.display,
                  period === 'live',
                  inverted
                )} */}
              </span>
            </Col>
          );
        })}
      </Row>
    </Col>
  );

  // TODO
  console.log('Props Header');
  console.log(props);

  const periodOptions = Object.keys(props.cumulativeValues).map((period) => {
    const periodSymbols = { live: 'H', day: 'D', week: 'W', month: 'M', year: 'Y' };
    return (
      <Option key={period} value={period}>
        {periodSymbols[period]}
      </Option>
    );
  });

  const loadOptions = props.loads.map((load) => {
    return (
      <Option key={load} value={load}>
        {load.split('_')[1]}
      </Option>
    );
  });

  // select are mobile dropdown select component
  const selects = (
    <Fragment>
      <Col xs={4} style={{ textAlign: 'center' }}>
        <Select
          className="dashboard-select"
          defaultValue={'live'}
          value={props.period}
          size="small"
          style={{ width: '100%' }}
          onSelect={(option) => props.setPeriod(option)}
        >
          {periodOptions}
        </Select>
      </Col>
      <Col xs={11} style={{ textAlign: 'center' }}>
        <Select
          className="dashboard-select"
          defaultValue={props.load}
          size="small"
          style={{ width: '100%' }}
          onSelect={(option) => props.setLoad(option)}
        >
          {loadOptions}
        </Select>
      </Col>
    </Fragment>
  );

  function onChange(e) {
    console.log(e.target.value);
    props.setDisplay(e.target.value);
  }

  return (
    <Row
      align="middle"
      justify="space-around"
      gutter={0}
      style={{
        width: '100%',
        backgroundColor: '#1890ff',
        zIndex: 1,
        height: mobile ? 50 : 'inherit',
      }}
    >
      <Col xs={3} sm={1} style={{ textAlign: 'center', lineHeight: mobile ? 0 : 'inherit' }}>
        <SliderTrigger sliderTriggered={true} />
      </Col>
      {window.innerWidth >= 768 ? loads : selects}
      <Col xs={4} sm={2} className="switch-header" style={{ textAlign: 'center' }}>
        {/* <Switch
          style={{ width: mobile ? 48 : 66, height: mobile ? 24 : 33 }}
          checkedChildren={<ThunderboltOutlined style={{ fontSize: mobile ? 14 : 20 }} />}
          unCheckedChildren={<DollarOutlined style={{ fontSize: mobile ? 14 : 20 }} />}
          defaultChecked
          onChange={onChange}
        /> */}

        {/* {mobile ? ( */}
        <div>
          <Select
            className="dashboard-select"
            defaultValue={'energyData'}
            size={mobile ? 'small' : 'middle'}
            style={
              mobile
                ? {
                    width: '90%',
                    fontSize: mobile ? 14 : 16,
                    color: '#fff',
                    border: '1px solid white',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '0.3em',
                  }
                : {
                    width: '90%',
                    fontSize: mobile ? 14 : 16,
                    backgroundColor: '#1890ff',
                    color: '#fff',
                    border: '1px solid white',
                    borderRadius: '0.3em',
                  }
            }
            onSelect={(option) => {
              props.setDisplay(option);
            }}
            bordered={false}
          >
            <Option
              key={'energyData'}
              value={'energyData'}
              style={{
                fontSize: mobile ? 14 : 18,
                color: '#666666',
              }}
            >
              {mobile ? (
                <ThunderboltOutlined />
              ) : (
                <div>
                  <ThunderboltOutlined /> Energy
                </div>
              )}
            </Option>
            <Option
              key={'dollarData'}
              value={'dollarData'}
              style={{
                fontSize: mobile ? 14 : 18,
                color: '#666666',
              }}
            >
              {mobile ? (
                <DollarOutlined />
              ) : (
                <div>
                  <DollarOutlined /> Dollars
                </div>
              )}
            </Option>
            <Option
              key={'emissionsData'}
              value={'emissionsData'}
              style={{
                fontSize: mobile ? 14 : 18,
                color: '#666666',
              }}
            >
              {mobile ? (
                <WarningOutlined />
              ) : (
                <div>
                  {emissions_type === 'solar' ? (
                    <>
                      <CloudSyncOutlined /> Abated
                    </>
                  ) : (
                    <>
                      <WarningOutlined /> Emissions
                    </>
                  )}
                </div>
              )}
            </Option>
          </Select>
        </div>

        {/*
        // ) : (
        //   <Radio.Group name="radiogroup" defaultValue={'energyData'} onChange={onChange} buttonStyle="solid" style={{border: '0px solid white'}}>
        //     <Radio.Button
        //       value={'energyData'}
        //       style={
        //         props.display === 'energyData'
        //           ? { backgroundColor: '#1890ff', border: '1px solid white'}
        //           : { backgroundColor: '#1890ff', border: '0px solid white' }
        //       }
        //     >
        //       <ThunderboltOutlined style={{ fontSize: mobile ? 14 : 20, color: 'whitesmoke' }} />
        //     </Radio.Button>
        //     <Radio.Button
        //       value={'dollarData'}
        //       style={
        //         props.display === 'dollarData'
        //           ? { backgroundColor: '#1890ff', border: '1px solid white' }
        //           : { backgroundColor: '#1890ff', border: '0px solid white' }
        //       }
        //     >
        //       <DollarOutlined style={{ fontSize: mobile ? 14 : 20, color: 'whitesmoke' }} />
        //     </Radio.Button>
        //     <Radio.Button
        //       value={'emissionsData'}
        //       style={
        //         props.display === 'emissionsData'
        //           ? { backgroundColor: '#1890ff', border: '1px solid white' }
        //           : { backgroundColor: '#1890ff', border: '0px solid white' }
        //       }
        //     >
        //       <FrownOutlined style={{ fontSize: mobile ? 14 : 20, color: 'whitesmoke' }} />
        //     </Radio.Button>
        //   </Radio.Group>
        // )}
        */}
      </Col>
    </Row>
  );
};

export default DashboardHeader;
