import { API } from 'aws-amplify';

export default async function getCustomers(customers) {
    return await new Promise((resolve, reject) => {
        const apiName = 'customerapi';
        const path = '/customer';
        const body = {
            customers: customers,
            env: process.env.REACT_APP_ENV
        };

        API
          .post(apiName, path, {body})
          .then(response => resolve(response))
          .catch(error => {
            console.log('error', error);
        });
    });
}
