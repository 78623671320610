import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ApplicationLayout from '../common/ApplicationLayout';
import DashboardScreen from '../dashboard/DashboardScreen';
import CustomerScreen from '../customer/CustomerScreen';
import ParticipantsScreen from '../participants/ParticipantsScreen';
import ExportScreen from '../export/ExportScreen';
import { store } from '../context/AppContext';
import InviteScreen from '../settings/InviteScreen';
import DeviceScreen from '../settings/DeviceScreen';
import { GetCustomerIds } from './helpers/getCustomerIds';
import { UpdateDevices } from '../customer/helpers/updateDevices';
import AnalyticsScreen from '../analytics/AnalyticsScreen';

function usePageViews() {
  //If path ends in "/" and other characters are present, remove the last "/"
  const location = (window.location.pathname.match(/^(.*[^\/])\/$/gm == null)) ? 
                    window.location.pathname : 
                    window.location.pathname.slice(0, -1)
  React.useEffect(() => {
    window.analytics.page(location)
  }, [location])
}

const Navigation = () => {
  const context = useContext(store);
  const [intialDeviceUpdate, setInitialDeviceUpdate] = useState(true);
  const { state } = context;

  usePageViews();

  // UpdateDevices Use Effect
  useEffect(() => {
    async function HandleDeviceUpdates() {
      let interval = null;
      if (intialDeviceUpdate) {
        console.log('inital device update');
        setInitialDeviceUpdate(false);
        await GetCustomerIds();
        await UpdateDevices();
      }
      interval = setInterval(async () => {
        console.log('5 min inverval device update');
        await UpdateDevices();
      }, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
    HandleDeviceUpdates();
  }, []);

  return (
    <Router>
      <ApplicationLayout>
        <Switch>
          <Route path='/' exact={true} component={DashboardScreen} />
          <Route path='/participants' exact={true} component={ParticipantsScreen} />
          <Route path='/dashboard' exact={true} component={DashboardScreen} />
          <Route path='/customer' exact={true} component={CustomerScreen} />
          <Route path='/export' exact={true} component={ExportScreen} />
          <Route path='/invite' exact={true} component={InviteScreen} />
          <Route path='/device' exact={true} component={DeviceScreen} />
          <Route path='/analytics' exact={true} component={AnalyticsScreen} />
          <Route
            path='/*'
            exact={true}
            render={() => {
              if (state.groupMaster || state.groupBlind) return <Redirect to='/analytics' />;

              return <Redirect to='/dashboard' />;
            }}
          />
        </Switch>
      </ApplicationLayout>
    </Router>
  );
};

export default Navigation;
