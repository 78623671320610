import React, { useEffect, useState, useContext } from 'react';
import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { store } from '../context/AppContext';
import { AnalyticsDashboard, LineChart, BarChart, PieChart, SessionsByHourChart } from 'react-analytics-charts';
import axios from 'axios';
//import google from 'googleapis'
//import serviceAccountKey from '../../gatest-337202-8cfcb91ef158.json'

let errorCaught = false;
window.onerror = (msg, url, lineNo, columnNo, error) => {
    if (/Script error\./.test(msg) && !errorCaught) {
        alert("An AdBlocker has disabled the Analytics Dashoard. Please disable your AdBlocker and reload the page.");
        errorCaught = true;
    }
}

const AnalyticsScreen = () => {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [accessToken, setAccessToken] = useState("")
  const [loading, setLoading] = useState(true)
  const endpoint = "https://47vsembi0f.execute-api.ap-southeast-2.amazonaws.com/default/GAFetchAccessToken"
  let startTime;
  let accessTokenRecieved;
  let graphsRendered;

  useEffect(() => {
    if (accessToken === "") {
        startTime = performance.now();
        axios(endpoint).then((res) => {
            console.log("Access token received")
            accessTokenRecieved = performance.now();
            setAccessToken(res.data.ga_access_token);
        }).catch((error) => {
            console.error("Error fetching access token", error)
        })
    }
  })

  const onRenderFunction = () => {
    if (loading) {
        graphsRendered = performance.now()
        console.log("Token fetch time: ", accessTokenRecieved - startTime)
        console.log("Graph render time: ", graphsRendered - accessTokenRecieved)
        console.log("Total time: ", graphsRendered - startTime)
    }

    setLoading(false)
  }

  if (!state?.groupMaster && !state?.groupBlind) return <Redirect to="/dashboard" />

  return (
    <div className='analytics-dashboard-container'>
        {loading ? <Spin /> : null}
        <AnalyticsDashboard 
            authOptions={{ serverAuth: { access_token: accessToken } }}
            loadingText=''
            hideRefreshButton
            renderCharts={(gapi, viewId) => {
                onRenderFunction()
                return (
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", maxWidth: "1000px" }}>
                        <LineChart
                            gapi={gapi}
                            query={{
                                ids: viewId,
                                "start-date": "14daysAgo",
                                "end-date": "today",
                                metrics: "ga:sessions,ga:users,ga:pageviews",
                                dimensions: "ga:date"
                            }}
                            container="sessions-by-date-chart"
                            options={{
                                title: "Sessions, Users, and Page Views (14 days)"
                            }}
                        />
                        <BarChart
                            gapi={gapi}
                            query={{
                                ids: viewId,
                                "start-date": "14daysAgo",
                                "end-date": "today",
                                metrics: "ga:pageviews",
                                dimensions: "ga:pagePath"
                            }}
                            container="pageviews-per-path-chart"
                            options={{
                                title: "Page Views Per Path (14 days)"
                            }}
                        />
                        <PieChart
                            gapi={gapi}
                            query={{
                                ids: viewId,
                                "start-date": "14daysAgo",
                                "end-date": "today",
                                metrics: "ga:sessions,ga:users,ga:pageviews",
                                dimensions: "ga:deviceCategory",
                            }}
                            container="traffic-by-device-category-chart"
                            options={{
                                title: "Traffic By Device Category (14 Days)",
                            }}
                        />
                        <SessionsByHourChart gapi={gapi} viewId={viewId} days={14} />
                    </div>
                )
            }} 
        />
    </div>
  );
}

export default AnalyticsScreen;