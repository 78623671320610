import React from 'react';
import { Row } from 'antd';
import DashboardHeader from './DashboardHeader';

const Message = (props) => {
  return (
    <Row style={{ height: '100vh', width: '100%' }}>
      <Row style={{ width: '100%' }}>
        <DashboardHeader periods={false} />
      </Row>
      <Row
        align="middle"
        justify="space-around"
        style={{
          width: '100%',
          height: 'calc(100% - 64px)',
          color: '#fff',
          backgroundColor: '#1890ff',
        }}
      >
        <span>{props.message}</span>
      </Row>
    </Row>
  );
};

export default Message;
