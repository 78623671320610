import { Input, Row, Form } from "antd";
import React, { useEffect } from "react";

export default function CreateOrganisation(props) {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(props.customer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customer]);

    const onAbnChange = (value) => {
        const newValue = value.replace(/\D/g,'');

        form.setFieldsValue({
            abn: newValue
        });

        props.changeCustomer('abn', newValue);
    }

    return (
        <Row justify="center">
            <Form form={form}>
                <Form.Item
                    name="abn"
                    label="ABN"
                >
                    <Input onChange={(event) => onAbnChange(event.target.value)}/>
                </Form.Item>
                <Form.Item
                    name="entityName"
                    label="Entity name"
                >
                    <Input disabled onChange={(event) => props.changeCustomer('entityName', event.target.value)} />
                </Form.Item>
                <Form.Item
                    name="entityType"
                    label="Entity type"
                >
                    <Input disabled onChange={(event) => props.changeCustomer('entityType', event.target.value)} />
                </Form.Item>
            </Form>
        </Row>
    )
}
