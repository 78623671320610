import React, { Fragment, useState, useEffect, useContext } from 'react';
import '../ApplicationLayout.css';
import Helmet from 'react-helmet';
import { Menu, Button, Modal } from 'antd';
import { PoweroffOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { store } from '../../context/AppContext';
import sliderMenu from '../../static/sliderMenu';
import fluxpower_logo from '../../images/logo.png';

const { SubMenu } = Menu;

export default function SliderBar(props) {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [barState, setBarState] = useState({
    admin: false,
    title: 'Fluxpower',
    selectedMenuKeys: ['1'],
    energySystems: [],
    pathname: '',
  });
  const history = useHistory();

  useEffect(() => {
    syncActiveRouteInSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.pathname !== barState.pathname) {
      syncActiveRouteInSlider();
      collapseSliderOnDashboard(props.pathname);
    }
  });

  const syncActiveRouteInSlider = () => {
    let currentMenus = sliderMenu.filter((item) => props.pathname.includes(item.path || ''));
    if (!currentMenus.length)
      currentMenus = sliderMenu
        .map((item) => {
          return item.submenus?.filter((submenu) => {
            return props.pathname.includes(submenu.path || '');
          });
        })
        .find((element) => element);

    setBarState({
      ...barState,
      selectedMenuKeys: currentMenus.length ? [currentMenus[currentMenus.length - 1].key] : ['1'],
      title: currentMenus.length ? currentMenus[currentMenus.length - 1].name : 'Dashboard',
      pathname: props.pathname,
    });
  };

  const collapseSliderOnDashboard = (pathname) =>
    props.siderCollapse(['/', '/dashboard'].includes(pathname));

  const confirmLogout = () => {
    Modal.confirm({
      title: 'Are you sure ?',
      content: 'You have been log out from fluxpower account',
      onOk: async () => {
        dispatch({ type: 'CLEAR' });
        await localStorage.clear();
        Auth.signOut();
        history.push('/customer');
        window.location.reload();
      },
      onCancel() {},
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>{barState.title}</title>
      </Helmet>
      <div className="logo">
        <img className="logo-img" src={fluxpower_logo} alt="fluxpower_logo" />
        <span className="logo-text">Fluxpower</span>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={barState.selectedMenuKeys}
        defaultOpenKeys={['7']}
      >
        {sliderMenu.map((item) => {
          if (!barState.isAdmin && item.admin) {
            return null;
          }
          if (!state.groupMaster && !state.groupBlind && item.name === 'Participants') {
            return null;
          }
          if (!state.groupMaster && !state.groupBlind && item.name === 'Analytics') {
            return null;
          }
          if ((state.groupMaster || state.groupBlind) && item.name === 'Export') {
            return null;
          }
          if (item.name === 'Export' && !state.energySystems?.length) {
            return null;
          }

          if (item.submenus) {
            const menuItems = item.submenus.map((submenu) => {
              return (
                <Menu.Item key={submenu.key} className={submenu.disabled ? 'disabled-link' : ''}>
                  <Link to={submenu.path} onClick={() => collapseSliderOnDashboard(submenu.path)}>
                    {submenu.icon}
                    <span className="nav-text">{submenu.name}</span>
                  </Link>
                </Menu.Item>
              );
            });

            return (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    {item.icon}
                    <span className="nav-text">{item.name}</span>
                  </span>
                }
              >
                {menuItems}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key} className={item.disabled ? 'disabled-link' : ''}>
                <Link to={item.path} onClick={() => collapseSliderOnDashboard(item.path)}>
                  {item.icon}
                  <span className="nav-text">{item.name}</span>
                </Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
      <div className="customer" style={props.siderCollapsed ? { left: -200 } : undefined}>
        <Link to="/customer?switch=true">
          <Button type="default" icon={<UserSwitchOutlined />} block>
            Switch customer
          </Button>
        </Link>
      </div>
      <div className="logout" style={props.siderCollapsed ? { left: -200 } : undefined}>
        <Button type="primary" onClick={confirmLogout} block icon={<PoweroffOutlined />}>
          Logout
        </Button>
      </div>
    </Fragment>
  );
}
