import * as React from 'react';
import {
  DashboardOutlined,
  CrownOutlined,
  WalletOutlined,
  ContactsOutlined,
  BankOutlined,
  DollarOutlined,
  ExportOutlined,
  SettingOutlined,
  MailOutlined,
  ControlOutlined,
  MonitorOutlined,
} from '@ant-design/icons';

const sliderMenu = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardOutlined />,
    disabled: false,
    key: '1',
  },
  {
    name: 'Participants',
    path: '/participants',
    icon: <ContactsOutlined />,
    disabled: false,
    key: '4',
  },
  {
    name: 'Analytics',
    path: '/analytics',
    icon: <MonitorOutlined />,
    disabled: false,
    key: '10',
  },
  
  {
    name: 'Bank accounts',
    path: '/bank_accounts',
    icon: <BankOutlined />,
    disabled: true,
    key: '5',
  },
  {
    name: 'Transactions',
    path: '/transactions',
    icon: <WalletOutlined />,
    disabled: true,
    key: '6',
  },
  {
    name: 'FluxPay',
    path: '/fluxpower_accounts',
    icon: <DollarOutlined />,
    disabled: true,
    key: '7',
  },
  {
    name: 'Export',
    path: '/export',
    icon: <ExportOutlined />,
    disabled: false,
    key: '2',
  },
  {
    name: 'Admin',
    path: '/admin',
    icon: <CrownOutlined />,
    key: '22',
    disabled: false,
    admin: true,
  },
  {
    name: 'Settings',
    icon: <SettingOutlined />,
    path: '/settings',
    submenus: [
      {
        name: 'Invite users',
        path: '/invite',
        icon: <MailOutlined />,
        disabled: false,
        key: '11',
      },
      {
        name: 'Configure device',
        path: '/device',
        icon: <ControlOutlined />,
        disabled: false,
        key: '10',
      },
    ],
    disabled: false,
    key: '8',
  },
];

export default sliderMenu;
