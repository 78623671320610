export default function latestTimestamp(energyAccumulator) {
  let latestTimestamp = 0;

  try {
    for (let item of energyAccumulator) {
      // console.log('______________NEW ITEM___________________________');

      let dateParts = item.date.split('-');
      let dayTime = dateParts[2].split('T');
      // console.log(dateParts);
      // console.log(dayTime);

      // Update dayTime[1]
      let updatedDayTime = dayTime[1].split(':');

      // Hours
      updatedDayTime[0] = ('0' + updatedDayTime[0]).slice(-2);

      // Minutes
      updatedDayTime[1] = ('0' + updatedDayTime[1]).slice(-2);

      dateParts[1] = ('0' + dateParts[1]).slice(-2);
      dateParts[2] = ('0' + dayTime[0]).slice(-2) + 'T' + updatedDayTime[0] + ':' + updatedDayTime[1];
      // console.log(dateParts);
      let timestamp = Date.parse(dateParts.join('-'));
      // console.log(timestamp);
      // console.log(latestTimestamp);

      if (timestamp > latestTimestamp) latestTimestamp = timestamp;

      // if (item.date === '2022-1-18T9:40') {
      //   console.log('timestamp', timestamp);
      //   console.log('latestTimestamp', latestTimestamp);
      // }
    }

    return latestTimestamp / 1000;
  } catch (error) {
    console.log('LatestTimestamp Failed again');
    console.log(energyAccumulator);
  }
}
