/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:50aca67b-e5c8-44aa-907a-7d2716af34c0",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_u6DmVSj0e",
    "aws_user_pools_web_client_id": "2n8nh6nfr0i0g4oemipn467m0r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "userapi",
            "endpoint": "https://aifibnaf1e.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "customerapi",
            "endpoint": "https://m74yqq322f.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "energysystemapi",
            "endpoint": "https://skgqlph8be.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "energyaccumulatorapi",
            "endpoint": "https://1dip5efed6.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "energydataapi",
            "endpoint": "https://ulnjkq53o2.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "updateenergyapi",
            "endpoint": "https://f8qdwom91f.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "exportdataapi",
            "endpoint": "https://xqo98oalg7.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "invitationapi",
            "endpoint": "https://hwzrk7g1e4.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "switchapi",
            "endpoint": "https://d7hxkcvbu5.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "abnapi",
            "endpoint": "https://waoyb9e1pf.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_mobile_analytics_app_id": "1a1c9543790f4d499af7c5c9caffebd3",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
