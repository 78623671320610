export function formatLabel(option, datum, period) {
  const datetime = new Date(datum.timestamp * 1000);
  const hour = datetime.getHours() % 12 || 12;
  const ampm = datetime.getHours() < 12 || datetime.getHours() === 24 ? 'am' : 'pm';
  let time;

  // console.log('Datum');
  // console.log(datum);

  if (period === 'live')
    time =
      ('0' + hour).slice(-2) +
      ':' +
      ('0' + datetime.getMinutes()).slice(-2) +
      ':' +
      ('0' + datetime.getSeconds()).slice(-2) +
      ampm;
  else time = ('0' + hour).slice(-2) + ':' + ('0' + datetime.getMinutes()).slice(-2) + ampm;

  if (option === 'dollarData') {
    if (period === 'live') return `${time}: $${Math.abs(datum.amount.toFixed(7))}`;
    else
      return `${time}: $${Math.abs(datum.amount.toFixed(2))}\nAccumulated: $${Math.abs(datum.accumulator.toFixed(2))}`;
  } else if (option === 'emissionsData') {
    if (period === 'live')
      return `${time}: ${Math.abs(datum.amount.toFixed(3))} kgCO2e/h\nCO2e Grid: ${Math.abs(
        datum.gridIntensity
      )} kgCO2e/kWh\nCO2e proportion: ${Math.abs(datum.emissionsIntensityProportion)}% ${
        datum.abated === null ? '' : `\nAbated: ${Math.abs(datum.abated)} kgCO2e/h`
      }`;
    // TODO: make sure the live data makes sense [Units are wrong] (kgCO2e/s or something)
    else
      return `${time}: ${Math.abs(datum.amount.toFixed(2))} kgCO2e\nAccumulated: ${Math.abs(
        datum.accumulator.toFixed(2)
      )} kgCO2e ${
        datum.abated === null
          ? ''
          : `\nAbated: ${Math.abs(datum.abated)} kgCO2e\nAbated Accumulated: ${Math.abs(
              datum.abatedAccumulator
            )} kgCO2e`
      }`;
  } else {
    if (period === 'live') return `${time}: ${Math.abs(datum.amount.toFixed(7))} kW`;
    else
      return `${time}: ${Math.abs(datum.amount.toFixed(2))} kWh\nAccumulated: ${Math.abs(
        datum.accumulator.toFixed(2)
      )} kWh`;
  }
}

export function setXTickValue(energies, period, index) {
  const mobile = window.innerWidth < 768;
  const latestTimestamp = Math.max.apply(
    Math,
    energies.map((item) => item.timestamp)
  );
  const date = new Date(latestTimestamp * 1000);

  if (period === 'live') {
    if (!energies.length) return '';

    const points = 3600 / energies[0].duration;
    const time = createXLabel(period, latestTimestamp, points, energies[0].duration, mobile ? index + 1 : index);

    if ((mobile ? index + 1 : index) % (points / (mobile ? 4 : 12)) === 0) return time;
    return '';
  }
  if (period === 'day') {
    const time = createXLabel(period, latestTimestamp, 288, 300, index);
    const offset = 12 - date.getMinutes() / 5;

    if ((mobile ? index + 30 : index + 1) % (mobile ? 48 : 12) === offset) return time;
    return '';
  }
  if (period === 'week') {
    const time = createXLabel(period, latestTimestamp, 168, 3600, index);
    const offset = 24 - date.getHours();

    if ((index + 1) % 24 === offset) return mobile ? time.charAt(0) : time;
    return '';
  }

  return '';
}

export function formatMonthLabel(display, datum) {
  const text = datum.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const accumulatedValue = datum.accumulator.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (display === 'dollarData') return `${datum.time}: $${text}\nAccumulated: $${accumulatedValue}`;
  if (display === 'emissionsData')
    return `${datum.time}: ${text} kgCO2e\nAccumulated: ${accumulatedValue} kgCO2e ${
      datum.abated === null
        ? ''
        : `\nAbated: ${Math.abs(datum.abated)} kgCO2e\nAbated Accumulated: ${Math.abs(datum.abatedAccumulator)} kgCO2e`
    }`;

  return `${datum.time}: ${text} kWh\nAccumulated: ${accumulatedValue} kWh`;
}

function createXLabel(period, latestTimestamp, points, duration, index) {
  const firstTimestamp = latestTimestamp - (points - 1) * duration;
  const baseDate = new Date(firstTimestamp * 1000);

  let tickDate = new Date(baseDate.setSeconds(baseDate.getSeconds() + index * duration));

  if (period === 'week') return tickDate.toLocaleString('en-us', { weekday: 'long' });

  const hour = tickDate.getHours() % 12 || 12;
  const ampm = tickDate.getHours() < 12 || tickDate.getHours() === 24 ? 'am' : 'pm';
  let time = period === 'live' ? ('0' + hour).slice(-2) : hour;

  if (period === 'live') time = time + ':' + ('0' + tickDate.getMinutes()).slice(-2);

  return time + ampm;
}
