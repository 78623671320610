import { API } from 'aws-amplify';

export default async function getInvites() {
    const authData = JSON.parse(localStorage.getItem('authData'));

    const promise = new Promise((resolve, reject) => {
        const apiName = 'invitationapi';
        const path = `/invite?email=${authData.email}&env=${process.env.REACT_APP_ENV}`;

        API
            .get(apiName, path)
            .then(response => resolve(response))
            .catch(error => {
                console.log('error', error);
            });
    });

    return await promise;
}
