import React, { useContext, useEffect, useState, Fragment } from 'react';
import { message, Row, Result, Card, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { store } from '../context/AppContext';
import Chart from './components/Graph';
import DashboardHeader from './components/DashboardHeader';
import DashboardFooter from './components/DashboardFooter';
import getEnergySystems from '../energySystem/helpers/getEnergySystems.js';
import getEnergyAccumulator from './helpers/getEnergyAccumulator';
import getEnergyData from './helpers/getEnergyData';
import MessageScreen from './components/MessageScreen';
import extractLoads from './helpers/extractLoads';
import calculateCumulative from './helpers/calculateCumulative';
import latestTimestamp from './helpers/latestTimestamp';
import setInverted from './helpers/setInverted';
import shortDataToGraph from './helpers/shortDataToGraph';
import dataToGraph from './helpers/dataToGraph';
import GetAbated from './helpers/getAbated';
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';

// 0.001299892208245982
// 0.000649946104122991
// 0.00006499461041229907
const INCREMENT_TIME = 200;

const DashboardScreen = () => {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [fetchEnergy, setFetchEnergy] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [energyAccumulator, setEnergyAccumulator] = useState(undefined);
  const [energyData, setEnergyData] = useState(undefined);
  const [options, setOptions] = useState({
    energySystemId: undefined,
    period: 'day',
    load: undefined,
    display: 'energyData',
  });
  const [messageDisplayed, setMessageDisplayed] = useState(false);
  const [delta, setDelta] = useState({
    energy: [],
    dollar: [],
    energy_live: [],
    dollar_live: [],
  });
  const [cumulativeValues, setCumulativeValues] = useState(undefined);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const intervalID = setInterval(() => {
      console.log('Update Long Energy');
      // setLoading(true);
      energyStartup();
      generateDelta();
      // setRefresh(true);
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      console.log('Update Short Energy');
    }, 30 * 1000);

    return () => clearInterval(intervalID);
  }, []);

  // useEffect(() => {
  //   const intervalID = setInterval(() => {
  //     // console.log('Called');
  //     if (cumulativeValues && delta.energy.length > 0) {
  //       const random = Math.random() + 0.5;
  //       Object.keys(cumulativeValues.day.energyData).forEach((key, index) => {
  //         // Energy
  //         // console.log(cumulativeValues);
  //         cumulativeValues.day.energyData[key] += delta.energy[index] * random;
  //         cumulativeValues.week.energyData[key] += delta.energy[index] * random;
  //         cumulativeValues.month.energyData[key] += delta.energy[index] * random;
  //         cumulativeValues.year.energyData[key] += delta.energy[index] * random;
  //         cumulativeValues.live.energyData[key] += delta.energy_live[index] * random;

  //         // Dollar
  //         cumulativeValues.day.dollarData[key] += delta.dollar[index] * random;
  //         cumulativeValues.week.dollarData[key] += delta.dollar[index] * random;
  //         cumulativeValues.month.dollarData[key] += delta.dollar[index] * random;
  //         cumulativeValues.year.dollarData[key] += delta.dollar[index] * random;
  //         cumulativeValues.live.dollarData[key] += delta.dollar_live[index] * random;
  //       });
  //       setCumulativeValues({ ...cumulativeValues });
  //     } else {
  //       generateDelta();
  //     }
  //   }, INCREMENT_TIME);

  //   return () => clearInterval(intervalID);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [energyAccumulator, cumulativeValues, delta, energyData]);

  useEffect(() => {
    energySystemStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dashboardId]);

  useEffect(() => {
    if (state.energySystems && !loading) {
      setLoading(true);
      energyStartup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.energySystems]);

  useEffect(() => {
    if (energyAccumulator) checkDeviceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyAccumulator]);

  async function energySystemStartup() {
    if (!fetchEnergy && !loading && state.dashboardId) {
      setLoading(true);
      const response = await getEnergySystems(state.dashboardId);

      if (response.code === 200) {
        setFetchEnergy(true);
        const energySystems = JSON.parse(response.energySystem);

        await setLoading(false);
        if (!!energySystems.length) {
          await dispatch({ type: 'ENERGY_SYSTEMS', data: energySystems });
          setOptions({
            ...options,
            energySystemId: energySystems[0].identifierNumber,
            load: energySystems[0].identifierNumber + '_' + energySystems[0].deviceMap.find((ct) => !ct.disabled).label,
          });
        }
      } else {
        await setLoading(false);
      }
    }
  }

  async function energyStartup() {
    if (state.energySystems) {
      const accumulator_data = await getEnergyAccumulator(state.energySystems);
      setEnergyAccumulator(accumulator_data);
      const energy_data = await getEnergyData(state.energySystems);
      setEnergyData(energy_data);

      setFetchData(true);
    }

    setLoading(false);
  }

  async function checkDeviceStatus() {
    const timestamp = latestTimestamp(energyAccumulator[options.energySystemId]?.minute);

    if (
      !messageDisplayed &&
      timestamp <= Math.floor(Date.now() / 1000 - 60 * 15) &&
      timestamp > Math.floor(Date.now() / 1000 - 60 * 120)
    ) {
      await setMessageDisplayed(true);
      message.warning(`The data for this device is ${formatDistance(timestamp * 1000, Date.now())} delayed`, 4);
    }
    if (!messageDisplayed && timestamp <= Math.floor(Date.now() / 1000 - 60 * 120)) {
      await setMessageDisplayed(true);
      message.error(`This device is offline for ${formatDistance(timestamp * 1000, Date.now())}`, 4);
    }
  }

  function displayResultCard(timestamp) {
    if (options.period === 'live' && timestamp <= Math.floor(Date.now() / 1000 - 60 * 60)) return true;
    if (options.period === 'day' && timestamp <= Math.floor(Date.now() / 1000 - 24 * 60 * 60)) return true;
    if (options.period === 'week' && timestamp <= Math.floor(Date.now() / 1000 - 7 * 24 * 60 * 60)) return true;
    if (options.period === 'month' && timestamp <= Math.floor(Date.now() / 1000 - 30 * 7 * 24 * 60 * 60)) return true;
    if (options.period === 'year' && timestamp <= Math.floor(Date.now() / 1000 - 365 * 24 * 60 * 60)) return true;

    return false;
  }

  const setPeriod = (period) => {
    setOptions({ ...options, period: period });
  };

  const setLoad = (load) => {
    setOptions({ ...options, load: load, energySystemId: load.split('_')[0] });
  };

  const setDisplay = (display) => {
    setOptions({ ...options, display: display });
  };

  const generateDelta = () => {
    if (
      delta.energy.length === 0 &&
      energyAccumulator &&
      energyAccumulator[options.energySystemId] &&
      energyData &&
      energyData[options.energySystemId]
    ) {
      // Compute delta

      const minute = energyAccumulator[options.energySystemId]?.minute;
      const live_data = energyData[options.energySystemId]?.shortEnergy;
      let new_delta_energy = [];
      let new_delta_dollar = [];
      let new_delta_energy_live = [];
      let new_delta_dollar_live = [];
      let sum_energy = [];
      let sum_dollar = [];
      let sum_energy_live = [];
      let sum_dollar_live = [];
      for (let i = 0; i < minute[0].energyData.length; i++) {
        new_delta_energy.push(0);
        new_delta_dollar.push(0);
        new_delta_energy_live.push(0);
        new_delta_dollar_live.push(0);
        sum_energy.push(0);
        sum_dollar.push(0);
        sum_energy_live.push(0);
        sum_dollar_live.push(0);
      }

      // Historic Data
      for (let i = 0; i < minute.length; i++) {
        for (let j = 0; j < minute[i].energyData.length; j++) {
          sum_energy[j] += minute[i].energyData[j].value;
          sum_dollar[j] += minute[i].dollarData[j].value;
        }
      }

      // Live Data
      for (let i = 0; i < live_data.length; i++) {
        for (let j = 0; j < live_data[0].energyData.length; j++) {
          sum_energy_live[j] += live_data[i].energyData[j].value;
          sum_dollar_live[j] += live_data[i].dollarData[j].value;
        }
      }

      const t = 1000 / INCREMENT_TIME;

      for (let i = 0; i < minute[0].energyData.length; i++) {
        new_delta_energy[i] = sum_energy[i] / (5 * 60 * minute.length * t); // data every 5 minutes into every second
        new_delta_dollar[i] = sum_dollar[i] / (5 * 60 * minute.length * t); // data every 5 minutes into every second
        new_delta_energy_live[i] = sum_energy_live[i] / (30 * live_data.length * t); // data every 30 seconds into every second
        new_delta_dollar_live[i] = sum_dollar_live[i] / (30 * live_data.length * t); // data every 30 seconds into every second
      }
      console.log(new_delta_energy_live[0]);
      setDelta({
        energy: new_delta_energy,
        dollar: new_delta_dollar,
        energy_live: new_delta_energy_live,
        dollar_live: new_delta_dollar_live,
      });
    }
  };

  if (!state.customerId) return <Redirect to="/customer" />;
  if (!fetchEnergy || loading) return <MessageScreen message={<Spin />} />;
  if (fetchEnergy && !loading && !state.energySystems?.length)
    return <MessageScreen message="No device configured for this account" />;
  if (!fetchData) return <MessageScreen message={<Spin />} />;
  const timestamp = latestTimestamp(energyAccumulator[options.energySystemId]?.minute);

  if (!cumulativeValues || refresh) {
    setCumulativeValues(calculateCumulative(energyAccumulator, energyData, timestamp));
    return <MessageScreen message={<Spin />} />;
  }
  const mobile = window.innerWidth < 768;
  // console.log('Energy Data');
  // console.log(energyData);

  // console.log('delta');
  // console.log(delta);

  // let selectedLoad = options.load.split('_');
  // selectedLoad.shift();
  // selectedLoad = selectedLoad.join('_');

  // let emissions_type = 'grid';
  // for (let system of state.energySystems) {
  //   for (let device of system.deviceMap) {
  //     if (device.label === selectedLoad) {
  //       emissions_type = device.emissions_type;
  //     }
  //   }
  // }

  const abatedList = GetAbated(state.energySystems, energyAccumulator, energyData);
  return (
    <Fragment>
      <Row style={{ color: '#fff', backgroundColor: '#1890ff', height: '100%' }}>
        <Row id="dashboard-header" style={{ width: '100%' }}>
          <DashboardHeader
            cumulativeValues={cumulativeValues}
            setPeriod={setPeriod}
            display={options.display}
            load={options.load}
            setDisplay={setDisplay}
            period={options.period}
            periods={true}
            loads={extractLoads(state.energySystems)}
            setLoad={setLoad}
            energySystemId={options.energySystemId}
            energySystems={state.energySystems}
            abatedList={abatedList}
          />
        </Row>

        {displayResultCard(timestamp) ? (
          <Row align="middle" justify="center" style={{ width: '100%', height: 'calc(100% - 128px)' }}>
            <Card style={{ borderRadius: 5, backgroundColor: '#fafafa' }}>
              <Result
                status="warning"
                title={`The last data from your Wattwatcher device was received in ${new Date(
                  timestamp * 1000
                ).toLocaleString()}`}
              />
            </Card>
          </Row>
        ) : (
          <Row
            justify="center"
            align="bottom"
            style={{
              height: 'calc(100% - 128px)',
              width: mobile ? 'calc(100% + 30px)' : '100%',
              marginLeft: mobile ? -15 : 0,
              marginRight: mobile ? -15 : 0,
            }}
          >
            <Chart
              energySystems={state.energySystems}
              energyAccumulator={energyAccumulator}
              energyData={energyData}
              energySystemId={options.energySystemId}
              period={options.period}
              load={options.load}
              display={options.display}
            />
          </Row>
        )}

        {window.innerWidth >= 768 && (
          <Row id="dashboard-footer" justify="center" style={{ width: '99%' }}>
            <DashboardFooter
              period={options.period}
              display={options.display}
              cumulativeValues={cumulativeValues}
              loads={extractLoads(state.energySystems)}
              setLoad={setLoad}
              load={options.load}
              energySystemId={options.energySystemId}
              energySystems={state.energySystems}
              abatedList={abatedList}
            />
          </Row>
        )}
      </Row>
    </Fragment>
  );
};

export default DashboardScreen;
