import { API } from 'aws-amplify';

export default async function (customerId) {
  const promise = new Promise((resolve, reject) => {
    const apiName = 'energysystemapi';
    const path = `/energysystem?customer_id=${customerId}&env=${process.env.REACT_APP_ENV}`;

    API.get(apiName, path)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error', error);
      });
  });

  return await promise;
}
