export default function GetAbated(energySystems, accumulator, energyData) {
  let [grid_item_energySystem, grid_item_label] = GetEnergySystemIdAndLabel(energySystems, 'grid');
  let [solar_item_energySystem, solar_item_label] = GetEnergySystemIdAndLabel(energySystems, 'solar');

  // console.log('Grid Label, system', grid_item_label, grid_item_energySystem);
  // console.log('Solar Label, system', solar_item_label, solar_item_energySystem);

  function GetEnergySystemIdAndLabel(energySystems, emissions_type) {
    let energySystemId = null;
    let label = null;

    for (let system of energySystems) {
      for (let item of system.deviceMap) {
        if (item.emissions_type === emissions_type) {
          label = `${item.label}`;
          energySystemId = system.identifierNumber;
          return [energySystemId, label];
        }
      }
    }

    return [energySystemId, label];
  }

  function ConvertTimeInterval(timeInterval) {
    switch (timeInterval) {
      case 'minute': {
        return 'day';
      }
      case 'hour': {
        return 'week';
      }
      case 'day': {
        return 'month';
      }
      case 'month': {
        return 'year';
      }
      default: {
        return '';
      }
    }
  }

  function CalculateLiveAbated(energyDataItem) {
    const { shortEnergy } = energyDataItem;
    let lastItem = shortEnergy[shortEnergy.length - 1];

    let energy =
      energyData[grid_item_energySystem].shortEnergy[shortEnergy.length - 1].energyData.find(
        (i) => i.label === grid_item_label
      ).value ?? 0;

    let emissions =
      energyData[grid_item_energySystem].shortEnergy[shortEnergy.length - 1].emissionsData.find(
        (i) => i.label === grid_item_label
      ).value ?? 0;

    let ei = energy && emissions && energy !== 0 ? emissions / energy : 0;

    let abated = lastItem.energyData.find((item) => item.label === solar_item_label).value * ei;

    return abated;
  }

  let solarItem = {
    live: 0,
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  };

  // Live
  if (energyData) {
    let energyDataItem = energyData[solar_item_energySystem];
    if (energyDataItem) {
      solarItem.live = Math.round(Math.abs(CalculateLiveAbated(energyDataItem)) * 1000) / 1000;
    }
  }

  // Day -> Month
  let energySystemItem = accumulator[solar_item_energySystem];
  if (!energySystemItem) {
    return solarItem;
  }

  for (let timeInterval of Object.keys(energySystemItem)) {
    let cumulativeAbated = 0;
    for (let i = 0; i < energySystemItem[timeInterval].length; i++) {
      let item = energySystemItem[timeInterval][i];

      let energy =
        accumulator[grid_item_energySystem][timeInterval][i].energyData.find((i) => i.label === grid_item_label)
          ?.value ?? 0;
      let emissions =
        accumulator[grid_item_energySystem][timeInterval][i].emissionsData.find((i) => i.label === grid_item_label)
          ?.value ?? 0;

      let ei = energy && emissions && energy !== 0 ? emissions / energy : 0;
      let abated = item.energyData.find((i) => i.label === solar_item_label).value * ei;
      cumulativeAbated += abated;
    }

    let convertedTimeInterval = ConvertTimeInterval(timeInterval);
    solarItem[convertedTimeInterval] = Math.round(Math.abs(cumulativeAbated) * 100) / 100;
  }

  console.log('Solar Item');
  console.log(solarItem);
  return solarItem;
}

// function GetEI(timeInterval, latestItemIndex) {
//   let energy = accumulator[grid_item_energySystem][timeInterval][latestItemIndex].energyData.find(
//     (i) => i.label === grid_item_label
//   ).value;
//   let emissions = accumulator[grid_item_energySystem][timeInterval][latestItemIndex].emissionsData.find(
//     (i) => i.label === grid_item_label
//   ).value;

//   return energy && emissions && energy !== 0 ? emissions / energy : 0;
// }

// function GetAbatedValue(timeInterval, latestItemIndex, ei = 0.5) {
//   return (
//     accumulator[solar_item_energySystem][timeInterval][latestItemIndex].energyData.find(
//       (i) => i.label === solar_item_label
//     ).value * ei
//   );
// }

// function GetLatestItemIndex(energySystemId, timeInterval) {
//   // do a loop and find out latest index

//   let accumulatorArray = accumulator[energySystemId][timeInterval];

//   let latestItemTime = 0;
//   let latestIndex = 0;
//   switch (timeInterval) {
//     case 'minute': {
//       for (let i = 0; i < accumulatorArray.length; i++) {
//         let { date } = accumulatorArray[i];
//         let dateArray = date.split('-');
//         let hourSplit = dateArray[2].split('T');
//         let minuteSplit = hourSplit[1].split(':');
//         let newItemTime = parseInt(
//           `${dateArray[0]}${('0' + dateArray[1]).slice(-2)}${('0' + hourSplit[0]).slice(-2)}${(
//             '0' + minuteSplit[0]
//           ).slice(-2)}${('0' + minuteSplit[1]).slice(-2)}`
//         );
//         if (newItemTime > latestItemTime) {
//           latestItemTime = newItemTime;
//           latestIndex = i;
//         } else {
//           return latestIndex;
//         }
//       }

//       return latestIndex;
//     }
//     case 'hour': {
//       for (let i = 0; i < accumulatorArray.length; i++) {
//         let { date } = accumulatorArray[i];
//         let dateArray = date.split('-');
//         let hourSplit = dateArray[2].split('T');
//         let newItemTime = parseInt(
//           `${dateArray[0]}${('0' + dateArray[1]).slice(-2)}${('0' + hourSplit[0]).slice(-2)}${(
//             '0' + hourSplit[1]
//           ).slice(-2)}`
//         );
//         if (newItemTime > latestItemTime) {
//           latestItemTime = newItemTime;
//           latestIndex = i;
//         } else {
//           return latestIndex;
//         }
//       }
//       return latestIndex;
//     }
//     case 'day': {
//       for (let i = 0; i < accumulatorArray.length; i++) {
//         let { date } = accumulatorArray[i];
//         let dateArray = date.split('-');
//         let newItemTime = parseInt(
//           `${dateArray[0]}${('0' + dateArray[1]).slice(-2)}${('0' + dateArray[2]).slice(-2)}`
//         );
//         if (newItemTime > latestItemTime) {
//           latestItemTime = newItemTime;
//           latestIndex = i;
//         } else {
//           return latestIndex;
//         }
//       }
//       return latestIndex;
//     }
//     case 'month': {
//       for (let i = 0; i < accumulatorArray.length; i++) {
//         let { date } = accumulatorArray[i];
//         let dateArray = date.split('-');
//         let newItemTime = parseInt(`${dateArray[0]}${('0' + dateArray[1]).slice(-2)}`);
//         if (newItemTime > latestItemTime) {
//           latestItemTime = newItemTime;
//           latestIndex = i;
//         } else {
//           return latestIndex;
//         }
//       }
//       return latestIndex;
//     }
//     default: {
//       return -1;
//     }
//   }
// }
