import React, { useState } from 'react';
import { Col, Row, DatePicker, Select, Button, Spin } from 'antd';
import { CSVLink } from 'react-csv';
import { deviceOptions, exportOptions } from '../helpers/selectOptions';
import onSubmit from '../helpers/onSubmit';
import MessageScreen from './MessageScreen';

const { RangePicker } = DatePicker;

export default function ExportForm(props) {
  const [csvData, setCsvData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [localState, setLocalState] = useState({
    deviceId: undefined,
    startDate: undefined,
    endDate: undefined,
    granularity: undefined,
    cleanData: false,
    selectOptions: [],
    exportType: undefined,
  });

  const handleTypeChange = (value) => {
    setLocalState({ ...localState, exportType: value });
  };

  const handleDeviceChange = (value) => {
    setLocalState({ ...localState, deviceId: value });
  };

  const handlePeriodChange = (value) => {
    setLocalState({ ...localState, granularity: value });
  };

  const handleDateChange = async (dates) => {
    if (dates) {
      const timeDifference = dates[1]._d.getTime() - dates[0]._d.getTime();
      const numberOfDays = timeDifference / (1000 * 60 * 60 * 24);

      let options = [
        { value: 'day', label: '1 day', exportName: 'byDay' },
        { value: 'week', label: '1 week', exportName: 'byWeek' },
        { value: 'month', label: '1 month', exportName: 'byMonth' },
      ];
      // , {value: "quarter", label: "1 quarter"}];

      if (numberOfDays <= 93) {
        options = [{ value: '30m', label: '30 minutes', exportName: 'by30min' }].concat(options);

        if (numberOfDays <= 31) {
          options = [{ value: '5m', label: '5 minutes', exportName: 'by5min' }].concat(options);

          // if (numberOfDays <= 7)
          //   options = [{value: "1m", label: "1 minute"}].concat(options);
        }
      }

      setLocalState({
        ...localState,
        selectOptions: options,
        startDate: dates[0]._d.getTime(),
        endDate: dates[1]._d.getTime(),
      });
    } else {
      setLocalState({ ...localState, startDate: '', endDate: '', selectOptions: [] });
    }
  };

  if (loading) return <MessageScreen message={<Spin />} />;

  const { exportType, deviceId, startDate, endDate, granularity, selectOptions } = localState;
  let startDateString;
  let endDateString;

  if (startDate && endDate) {
    const startDateStringParts = new Date(startDate).toISOString().slice(0, -5).split(':');
    startDateString = startDateStringParts[0] + 'h' + startDateStringParts[1] + 'm' + startDateStringParts[2] + 's';
    const endDateStringParts = new Date(endDate).toISOString().slice(0, -5).split(':');
    endDateString = endDateStringParts[0] + 'h' + endDateStringParts[1] + 'm' + endDateStringParts[2] + 's';
  }

  return (
    <Row align="top" justify="start" style={{ height: '100%', padding: '64px 20px' }}>
      <Col xs={24} sm={5} style={{ paddingRight: 5 }}>
        <Col span={24}>
          <span style={{ fontWeight: 'bold' }}>Export type:</span>
        </Col>
        <Select options={exportOptions} onChange={handleTypeChange} style={{ width: '100%' }} />
      </Col>
      <Col xs={24} sm={5} style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Col span={24}>
          <span style={{ fontWeight: 'bold' }}>Device:</span>
        </Col>
        <Select onChange={handleDeviceChange} style={{ width: '100%' }}>
          {deviceOptions(props.energySystems)}
        </Select>
      </Col>
      <Col
        xs={exportType && exportType !== 'liveSheet' ? 24 : 0}
        sm={exportType && exportType !== 'liveSheet' ? 8 : 0}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <Col span={24}>
          <span style={{ fontWeight: 'bold' }}>Period:</span>
        </Col>
        <RangePicker showTime onChange={handleDateChange} style={{ width: '100%' }} />
      </Col>
      <Col
        xs={exportType && exportType !== 'liveSheet' ? 24 : 0}
        sm={exportType && exportType !== 'liveSheet' ? 4 : 0}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <Col span={24}>
          <span style={{ fontWeight: 'bold' }}>Granularity:</span>
        </Col>
        <Select
          disabled={!selectOptions.length}
          onChange={handlePeriodChange}
          options={selectOptions}
          style={{ width: '100%' }}
        />
      </Col>
      <Col xs={24} sm={2} style={{ marginTop: 21, paddingLeft: 5 }}>
        <Button
          type="primary"
          disabled={
            exportType === 'liveSheet' && deviceId
              ? false
              : exportType && exportType !== 'liveSheet' && startDate && endDate && granularity
              ? false
              : true
          }
          onClick={async () => {
            setLoading(true);
            console.log('OB SUBMIT');
            await onSubmit(exportType, deviceId, startDate, endDate, granularity, props.energySystems, setCsvData);
            setLoading(false);
          }}
        >
          Request data
        </Button>
      </Col>
      {csvData?.code === 200 && (
        <Row justify="center" align="middle" style={{ width: '100%' }}>
          <CSVLink
            data={csvData.csv}
            filename={`${exportType.slice(6)} ${deviceId} ${
              localState.selectOptions.find((option) => option.value === granularity).exportName
            } ${startDateString} to ${endDateString}.csv`}
            target="_blank"
          >
            <Button type="primary" ghost>
              Download CSV file
            </Button>
          </CSVLink>
        </Row>
      )}
    </Row>
  );
}
