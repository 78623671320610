export default function(energySystems) {
    let loads = [];

    for (let energySystem of energySystems) {
        for (let load of energySystem.deviceMap) {
            if (!load.disabled) loads.push(energySystem.identifierNumber + '_' + load.label);
        }
    }

    return loads;
}
