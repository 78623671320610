import React, { useContext} from "react";
import {store } from '../../context/AppContext';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';

export default function SliderToggle() {
    const context = useContext(store);
    const { state, dispatch } = context;
    const mobile = window.innerWidth < 768;

    const onSliderToggle = () => { 
        dispatch({type: "TRIGGERED", data: !state.triggered});
    };


    return (
        state.triggered ?
            <MenuUnfoldOutlined className="trigger" onClick={onSliderToggle} style={mobile ? {fontSize: 22} : {}} /> :
            <MenuFoldOutlined className="trigger" onClick={onSliderToggle} style={{left: 200, fontSize: mobile ? 22 : ''}} />
    );
}
