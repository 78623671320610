import { API } from 'aws-amplify';

export default async function createUser() {
  const cognitoUser = JSON.parse(localStorage.getItem('authData'));

  return await new Promise((resolve, reject) => {
    const apiName = 'userapi';
    const path = '/user/create';
    const body = {
      id: cognitoUser.sub,
      firstName: cognitoUser['custom:first_name'],
      lastName: cognitoUser['custom:last_name'],
      env: process.env.REACT_APP_ENV,
    };

    API.post(apiName, path, { body })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error', error);
      });
  });
}
