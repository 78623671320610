import React from 'react';
import { StateProvider } from './client/context/AppContext';
import Amplify, { Analytics } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import './App.css';
import awsconfig from './aws-exports';
import Navigation from './client/navigation/Navigation';
import { Row, Col } from 'antd';
import logo_text from './client/images/logo_text.png';
import dotenv from 'dotenv';
dotenv.config();

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      localStorage.setItem('authData', JSON.stringify(authData?.attributes));
      localStorage.setItem('cognitoUser', authData?.username);
      //Segment user identification
      if (authData) {
        window.analytics.identify({
          firstName: authData.attributes["custom:first_name"],
          lastName: authData.attributes["custom:last_name"],
          email: authData.attributes["email"]
        });
      }
    });
  }, []);

  if (AuthState.SignedIn && user) {
    Analytics.autoTrack('pageView', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the event name, by default is 'pageView'
      eventName: 'pageView',
      // OPTIONAL, the attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
      attributes: {
        user: user?.attributes?.email,
      },
      type: 'multiPageApp',
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: 'AWSPinpoint',
      // OPTIONAL, to get the current page url
      getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
      },
    });

    Analytics.autoTrack('session', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
      attributes: {
        user: user?.attributes?.email,
      },
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: 'AWSPinpoint',
    });
  }

  return authState === AuthState.SignedIn && user ? (
    <StateProvider>
      <Navigation />
    </StateProvider>
  ) : (
    <Row>
      <Col span={24} className="signup-screen">
        <div className="logo">
          <img
            // justifyContent="center"
            // alignItems="center"
            className="logo-image"
            width={150}
            src={logo_text}
            alt="fluxpower_logo"
          />
        </div>
      </Col>
      <Col span={24}>
        <AmplifyAuthenticator className="amplify-authenticator" usernameAlias="email">
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: 'email',
                required: true,
              },
              {
                type: 'password',
                required: true,
              },
              {
                type: 'custom:first_name',
                required: true,
                label: 'First name *',
                placeholder: 'Enter your first name',
              },
              {
                type: 'custom:last_name',
                required: true,
                label: 'Last name *',
                placeholder: 'Enter your last name',
              },
            ]}
          />
        </AmplifyAuthenticator>
      </Col>
    </Row>
  );
}

export default App;
