import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  Row,
  Col,
  Select,
  Switch,
  Form,
  Card,
  Divider,
  Table,
  Modal,
  Button,
  Checkbox,
  TimePicker,
  Alert,
} from 'antd';
import { API } from 'aws-amplify';
import { store } from '../context/AppContext';
import { filterParticipants, formatSites } from '../participants/helpers/filterParticipants';
import getAllEnergySystems from '../participants/helpers/getAllEnergySystems';
import getEnergySystems from '../energySystem/helpers/getEnergySystems';
import { PlusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function DeviceScreen() {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [sites, setSites] = useState([]);
  const [energySystems, setEnergySystems] = useState(undefined);
  const [selected, setSelected] = useState({
    site: undefined,
    device: undefined,
  });
  const [energySystem, setEnergySystem] = useState(undefined);
  const [switches, setSwitches] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ruleType, setRuleType] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    if (state.customerId && !loading && !energySystems) energySystemsStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.customerId]);

  useEffect(() => {
    if (energySystems && !loading && !sites.length) sitesStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energySystems]);

  useEffect(() => {
    if (energySystem && !loading) getSwitches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energySystem]);

  async function energySystemsStartup() {
    setLoading(true);
    const response =
      state.groupMaster || state.groupBlind
        ? await getAllEnergySystems(state.customerId)
        : await getEnergySystems(state.customerId);

    if (response.code === 200) {
      setLoading(false);
      setEnergySystems(
        JSON.parse(
          state.groupMaster || state.groupBlind ? response.energySystems : response.energySystem
        )
      );
    } else {
      setLoading(false);
    }
  }

  const toggleSwitch = async (selected, item) => {
    const state = selected ? 'closed' : 'open';

    item.state = state;
    delete item.categoryLabel;

    const promise = new Promise((resolve, reject) => {
      const apiName = 'switchapi';
      const path = '/switch';
      const body = {
        deviceId: energySystem.identifierNumber,
        requestData: {
          switches: [item],
        },
      };

      API.post(apiName, path, { body })
        .then((response) => resolve(response))
        .catch((error) => {
          console.log('error', error);
        });
    });

    await promise;
  };

  async function sitesStartup() {
    setLoading(true);
    let response;

    if (state.groupMaster || state.groupBlind) {
      response = await filterParticipants(
        state.customerId,
        state.customerGroups,
        energySystems,
        dispatch,
        true
      );
    } else {
      const promise = new Promise((resolve, reject) => {
        const apiName = 'customerapi';
        const path = '/customer';
        const body = {
          customers: [state.customerId],
        };

        API.post(apiName, path, { body })
          .then((response) => resolve(response))
          .catch((error) => {
            console.log('error', error);
          });
      });

      response = await promise;

      if (response.code === 200) {
        const customer = JSON.parse(response.customers);

        response = await formatSites(customer, dispatch, energySystems);
      }
    }

    setSites(response);
    setLoading(false);
  }

  const onSiteChange = async (site) => {
    await setSelected({ ...selected, site: site, device: undefined });
    form.resetFields();
  };

  const onDeviceChange = (device) => {
    setSelected({ ...selected, device: device });
    setEnergySystem(energySystems.find((energySystem) => energySystem.identifierNumber === device));
  };

  const onRuleChange = (rule) => {
    setRuleType(rule);
  };

  if (!sites.length || !energySystems.length) return <Fragment />;

  const mobile = window.innerWidth < 768;

  const siteOptions = sites.map((site) => (
    <Option key={site.siteName} value={site.siteName}>
      {site.siteName}
    </Option>
  ));
  const deviceOptions = selected.site
    ? sites
        .find((site) => site.siteName === selected.site)
        .exportLinks.map((device) => (
          <Option key={device.key} value={device.key}>
            {device.key}
          </Option>
        ))
    : [];
  const ruleOptions = [
    'Time of day, day of week',
    'Spot price',
    'Weather',
    'Solar performance',
    'Soil moisture',
  ].map((option, index) => {
    return (
      <Option key={index} value={option}>
        {option}
      </Option>
    );
  });

  const loads = energySystem
    ? energySystem.deviceMap.map((load) => {
        return (
          <Col key={load.label} span={mobile ? 24 : Math.floor(24 / energySystem.deviceMap.length)}>
            <span style={{ color: '#000000a6' }}>
              <span style={{ fontWeight: 'bold', color: '#909090' }}>{load.label}: </span>CT
              {load.data.eReal.count > 1 ? 's' : ''} {load.data.eReal.join(', ')}
            </span>
          </Col>
        );
      })
    : [];

  async function getSwitches() {
    setLoading(true);

    const promise = new Promise((resolve, reject) => {
      const apiName = 'switchapi';
      const path = `/switch?device_id=${energySystem.identifierNumber}`;

      API.get(apiName, path)
        .then((response) => resolve(response))
        .catch((error) => {
          console.log('error', error);
        });
    });

    const response = await promise;

    if (response.code === 200 && response.body.switches)
      setSwitches(
        response.body.switches.map((item) => {
          return (
            <Col
              key={item.label}
              xs={24}
              sm={Math.floor(24 / (response.body.switches?.length || 1))}
              style={{ padding: 5 }}>
              <Card title={item.label}>
                <Row justify='start'>
                  <span>
                    <span style={{ fontWeight: 'bold', color: '#909090' }}>Contactor type: </span>
                    {item.contactorType === 'NO' ? 'Normally open' : 'Normally closed'}
                  </span>
                </Row>
                <Row justify='start'>
                  <span>
                    <span style={{ fontWeight: 'bold', color: '#909090' }}>State: </span>
                    {item.state}
                  </span>
                </Row>
                <Row justify='center' style={{ marginTop: 5 }}>
                  <span>
                    {item.contactorType === 'NO' ? item.closedStateLabel : item.openStateLabel}{' '}
                    <Switch
                      defaultChecked={
                        (item.contactorType === 'NO' && item.state === 'open') ||
                        (item.contactorType === 'NC' && item.state === 'closed')
                      }
                      onChange={(selected, event) => toggleSwitch(selected, item)}
                    />{' '}
                    {item.contactorType === 'NO' ? item.openStateLabel : item.closedStateLabel}
                  </span>
                </Row>
              </Card>
            </Col>
          );
        })
      );

    setLoading(false);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 16 },
    },
  };

  const dataSource = [
    {
      key: '1',
      type: 'Time of day, day of week',
      criteria: 'Monday, Tuesday and Friday',
      condition: '3PM',
      trigger: 'open',
      load: 'House power',
    },
    {
      key: '2',
      type: 'Soil moisture',
      criteria: 'moisture content',
      condition: 'less than 6%',
      trigger: 'open',
      load: 'Pump to be Growshed',
    },
  ];

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      key: 'condition',
    },
    {
      title: 'Trigger',
      dataIndex: 'trigger',
      key: 'trigger',
    },
    {
      title: 'Load',
      dataIndex: 'load',
      key: 'load',
    },
  ];

  const ruleForm = () => {
    if (!switches) return;

    const switchOptions = switches.map((item) => (
      <Option key={item.label} value={item.label}>
        {item.label}
      </Option>
    ));

    switch (ruleType) {
      case 'Time of day, day of week':
        const weekDays = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];

        return (
          <Fragment>
            <Col span={24}>
              <Checkbox.Group options={weekDays} defaultValue={[]} onChange={() => {}} />
            </Col>
            <Col span={24}>
              <TimePicker />
            </Col>
            <Col span={24}>
              <span>
                Trigger: close <Switch defaultChecked /> open
              </span>
            </Col>
            <Col span={24}>
              <span>Load: </span>
              <Select placeholder='Select site' onChange={onSiteChange}>
                {switchOptions}
              </Select>
            </Col>
          </Fragment>
        );

      default:
        return '';
    }
  };

  return (
    <Fragment>
      <Row align='top' justify='center' style={{ padding: 20, textAlign: 'center' }}>
        <Col style={{ width: 310 }}>
          <Form
            {...formItemLayout}
            style={{ width: '100%' }}
            initialValues={{
              device: null,
              site: selected.site,
            }}
            form={form}>
            <Form.Item label='Site' name='site'>
              <Select placeholder='Select site' onChange={onSiteChange}>
                {siteOptions}
              </Select>
            </Form.Item>
            <Form.Item label='Device' name='device' hidden={!selected.site}>
              <Select placeholder='Select device' onChange={onDeviceChange}>
                {deviceOptions}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        {selected.device && (
          <Fragment>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <h3>Loads</h3>
              </Col>
              {loads}
            </Row>
            <Divider />
            <Row justify='center' style={{ width: '100%' }}>
              <Col span={24}>
                <h3>Switches</h3>
              </Col>
              {switches ? (
                switches
              ) : (
                <Alert
                  message={`Your device ${selected.device} does not have switching capability`}
                  type='warning'
                  showIcon
                />
              )}
            </Row>
            {switches && (
              <Fragment>
                <Divider />
                <Row justify='center' style={{ width: '100%' }}>
                  <Col span={24}>
                    <h3>Switching rule table</h3>
                  </Col>

                  <Row justify='end' style={{ width: '100%' }}>
                    <Button type='link' onClick={() => setVisible(true)}>
                      <span>
                        <PlusCircleOutlined /> Add new rule
                      </span>
                    </Button>
                  </Row>

                  <Table dataSource={dataSource} columns={columns} style={{ width: '100%' }} />
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
      </Row>

      <Modal
        title='Switching rulel'
        visible={visible}
        onOk={() => {}}
        onCancel={() => setVisible(false)}>
        <Form {...formItemLayout} style={{ width: '100%' }}>
          <Form.Item label='Rule' name='rule'>
            <Select placeholder='Select rule' onChange={onRuleChange}>
              {ruleOptions}
            </Select>
          </Form.Item>
        </Form>

        {ruleForm()}
      </Modal>
    </Fragment>
  );
}
