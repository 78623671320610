import React, { useEffect, useState, useContext } from 'react';
import getEnergySystems from "../energySystem/helpers/getEnergySystems.js";
import MessageScreen from './components/MessageScreen';
import { store } from '../context/AppContext';
import ExportForm from './components/ExportForm';
import { Spin } from "antd";


export default function ExportScreen() {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [fetchEnergy, setFetchEnergy] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.customerId && !state.energySystems) energySystemStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function energySystemStartup() {
    if (!fetchEnergy && !loading && state.customerId) {
      setFetchEnergy(true);
      setLoading(true);
      const response = await getEnergySystems(state.dashboardId);

      if (response.code === 200) {
        const energySystems = JSON.parse(response.energySystem);

        await setLoading(false);
        if (!!energySystems.length) await dispatch({type: "ENERGY_SYSTEMS", data: energySystems});
      }
    }
  }

  if (state.loading) return <MessageScreen message={<Spin />} />;
  if (state.fetching) return <MessageScreen message={<Spin />} />;
  if (!state.energySystems?.length) return <MessageScreen message="No device configured to this account." />;


  return (
    <ExportForm energySystems={state.energySystems} />
  );
}
