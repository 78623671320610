import { message } from 'antd';
import getDataToExport from './getDataToExport';

export default async function onSubmit (exportType, deviceId, startDate, endDate, granularity, energySystems, setCsvData) {
    if (exportType === 'liveSheet') {
        if (!deviceId)
            message.warning('You must select a device.');
        else {
            const energySystem = Object.values(energySystems).find((item) => item.identifierNumber === deviceId);

            if (energySystem.sheetId) window.open(`https://docs.google.com/spreadsheets/d/${energySystem.sheetId}`, "_blank")
            else message.error(`Live sheet unavailable for device ${deviceId}`);
        }
    }
    else {
        if (!exportType || !deviceId || !startDate || !endDate || !granularity)
            message.warning('All fields are mandatory.');
        else {
            startDate = Math.floor(parseInt(startDate) / 1000);
            endDate = Math.floor(parseInt(endDate) / 1000);

            let dates = [startDate, endDate];
            if (endDate - startDate > 60 * 60 * 24 * 30) dates = splitDates(startDate, endDate);

            let requests = [];

            for (let index = 0; index < dates.length - 1; index++) {
                requests.push(getDataToExport(exportType, deviceId, dates[index], dates[index + 1], granularity));
            }

            await Promise.allSettled(requests).then(responses => {
                let csvData = [];

                for (let [index, response] of responses.entries()) {
                    if (response.status !== 'fulfilled' ||
                        response.value.code !== 200 ||
                        response.value.csv.constructor !== Array
                        ) continue;

                    const responseCsv = (csvData.length === 0) ? response.value.csv : response.value.csv.splice(2);
                    csvData = csvData.concat(responseCsv);
                }

                setCsvData({code: 200, csv: csvData});
            });
        }
    }
}

function splitDates(startDate, endDate) {
    let dates = [startDate];

    const limit = (60 * 60 * 24 * 30);

    const firstSplit = (Math.floor((startDate + limit) / (24*60*60)) * (24*60*60)) - (10 * 60 * 60);
    dates.push(firstSplit);

    if (firstSplit + limit >= endDate) {
        dates.push(endDate);

        return dates;
    }

    for (let date = firstSplit + limit; date < endDate - limit; date = date + limit) {
        dates.push(date);
    }

    dates.push(endDate);

    return dates;
}
