import getCustomers from '../../customer/helpers/getCustomers';
import getInvites from '../../invites/helpers/getInvites';
import createUser from '../../user/helper/createUser';
import getUser from '../../user/helper/getUser';

export async function GetCustomerIds() {
  let uniqueCustomerIds = [];

  // Fetch Customers
  let userResponse = await getUser();

  if (userResponse.code === 200) {
    const user = JSON.parse(userResponse.user);
    if (user.customers) {
      uniqueCustomerIds = user.customers;
    }
  }

  // Fetch Invites
  const invitesResponse = await getInvites();
  if (invitesResponse.code === 200) {
    let invites = JSON.parse(invitesResponse.invites);
    invites = invites.filter((invite) => invite.active === true);

    for (let invite of invites) {
      if (!uniqueCustomerIds.includes(invite.customerId)) {
        uniqueCustomerIds.push(invite.customerId);
      }
    }
  }

  localStorage.setItem('customerIds', JSON.stringify(uniqueCustomerIds));
  return uniqueCustomerIds;
}
