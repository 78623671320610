import React from "react";
import { Row, Form, Input, DatePicker } from "antd";

export default function CreateIndividual(props) {
    const authData = JSON.parse(localStorage.getItem("authData"));

    return (
        <Row justify="center">
            <Form
                initialValues = {{
                    firstName: authData["custom:first_name"],
                    lastName: authData["custom:last_name"]
                }}
            >
                <Form.Item
                    name="firstName"
                    label="First name"
                >
                    <Input onChange={(event) => props.changeCustomer('firstName', event.target.value)} />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label="Last name"
                >
                    <Input onChange={(event) => props.changeCustomer('lastName', event.target.value)} />
                </Form.Item>
                <Form.Item
                    name="dob"
                    label="Date of birth"
                >
                    <DatePicker onChange={(date, dateString) => props.changeCustomer('dob', dateString)} />
                </Form.Item>
            </Form>
        </Row>
    )
}
