import React, { useContext, useState } from 'react';
import { Row, Col, Input, Button, message } from 'antd';
import { API } from 'aws-amplify';
import { store } from '../context/AppContext';

export default function InviteScreen() {
  const context = useContext(store);
  const { state } = context;
  const [email, setEmail] = useState('');
  const authData = JSON.parse(localStorage.getItem('authData'));

  const onSubmit = async () => {
    const regex = /\S+@\S+\.\S+/;

    if (!regex.test(email)) message.error('Invalid email!');
    else {
      const response = await new Promise((resolve, reject) => {
        const apiName = 'invitationapi';
        const path = '/invite/create';
        const body = {
          email: email,
          customerId: state.customerId,
          customerSiteName: state.customerName,
          userName: authData.email,
          env: process.env.REACT_APP_ENV,
        };

        API.post(apiName, path, { body })
          .then((response) => resolve(response))
          .catch((error) => {
            console.log('error', error);
          });
      });

      if (response.code === 200) message.success('Your invitation has been sent!');
      else message.error('Failed to send invitation, please try again later.');

      console.log('response', response);
    }
  };

  return (
    <Row justify='center' align='middle' style={{ paddingTop: 100 }}>
      <Col>
        <div></div>
        {/* <p> logged in as {state.customerName}</p> */}
        <p>Invite user to access customer:</p>
        <Input
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Enter user's email"
        />
        <Button onClick={onSubmit}>Send invitation</Button>
      </Col>
    </Row>
  );
}
