import { API } from 'aws-amplify';

export default async function getEnergyData(energySystems) {
  let data = {};

  for (let energySystem of energySystems) {
    const deviceId = energySystem.identifierNumber;

    let timestamp = new Date();
    timestamp.setMinutes(timestamp.getMinutes() - 70);

    const promise = new Promise((resolve, reject) => {
      const apiName = 'energydataapi';
      const path = `/energydata?device_id=${deviceId}&start=${Math.floor(timestamp.getTime() / 1000)}&env=${
        process.env.REACT_APP_ENV
      }`;

      API.get(apiName, path)
        .then((response) => resolve(response))
        .catch((error) => {
          console.log('error', error);
        });
    });

    const response = await promise;

    if (response.code === 200) {
      data[deviceId] = { shortEnergy: [] };

      if (response.energies?.constructor === Array) {
        for (let record of response.energies) {
          if (record.shortEnergies) data[deviceId].shortEnergy.push(JSON.parse(record.shortEnergies));
        }
        data[deviceId].shortEnergy = [].concat.apply([], data[deviceId].shortEnergy);
      }
    }
  }

  return data;
}
