import React, { Fragment, useEffect, useState } from 'react';
import { Row, Select, Col, Button, Checkbox, message, Spin } from 'antd';
import { API } from 'aws-amplify';
import CreateIndividual from './CreateIndividual';
import CreateOrganisation from './CreateOrganisation';

const { Option } = Select;

const CreateCustomer = () => {
  const authData = JSON.parse(localStorage.getItem('authData'));
  const [customerType, setCustomerType] = useState(null);
  const [customer, setCustomer] = useState({
    firstName: authData['custom:first_name'],
    lastName: authData['custom:last_name'],
    dob: undefined,
    abn: undefined,
    entityName: undefined,
    entityType: undefined,
    abnResponse: undefined,
    declaration: false,
  });
  const [submited, setSubmited] = useState(false);

  const valid = () => {
    if (
      customer.firstName &&
      customer.lastName &&
      customer.dob &&
      (customerType === 'individual' || customer.entityName)
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (customer.abn && customer.abn.length === 11) {
      new Promise((resolve, reject) => {
        const apiName = 'abnapi';
        const path = `/abn?abn=${customer.abn}`;

        API.get(apiName, path)
          .then((response) => {
            const entity = JSON.parse(response.entity);
            setCustomer({
              ...customer,
              entityName: entity.EntityName,
              entityType: entity.EntityTypeName,
              abnResponse: entity,
            });
          })
          .catch((error) => {
            console.log('error', error);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.abn]);

  const changeCustomer = (key, value) => {
    setCustomer({ ...customer, [key]: value });
  };

  const onChange = (value) => {
    setCustomer({ ...customer, declaration: false });
    setCustomerType(value);
  };

  const onSubmit = async () => {
    if (!customer.declaration) {
      message.warning('You need to declare to be authorised to act on behalf of the entity');
      return;
    }

    if (
      !customer.firstName ||
      !customer.lastName ||
      !customer.dob ||
      (customerType !== 'individual' && !customer.entityName)
    ) {
      message.warning('Please, fill all the mandatory fields');
      return;
    }

    await setSubmited(true);

    const response = await new Promise((resolve, reject) => {
      const apiName = 'customerapi';
      const path = '/customer/create';
      const body = {
        customer,
        customerType,
        userId: authData.sub,
        env: process.env.REACT_APP_ENV,
      };

      API.post(apiName, path, { body })
        .then((response) => resolve(response))
        .catch((error) => {
          console.log('error', error);
        });
    });

    if (response.code !== 200) {
      message.warning(
        'There was a problem during the creation of your customer, please, try again later'
      );
    } else {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      {submited && (
        <Row
          align='middle'
          justify='center'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            backgroundColor: '#00000055',
            zIndex: 1,
          }}>
          <Spin />
        </Row>
      )}
      <Row justify='center' style={{ marginBottom: 20, marginTop: 20 }}>
        <Select placeholder='Select customer type' onChange={onChange}>
          <Option value='individual'>Individual</Option>
          <Option value='soleTrader'>Sole Trader</Option>
          <Option value='company'>Company</Option>
          <Option value='trust'>Trust</Option>
        </Select>
      </Row>
      {customerType && customerType !== 'individual' && (
        <CreateOrganisation changeCustomer={changeCustomer} customer={customer} />
      )}
      {customerType && <CreateIndividual changeCustomer={changeCustomer} customer={customer} />}
      {customerType && (
        <Checkbox
          disabled={!valid()}
          checked={customer.declaration}
          onChange={(event) => setCustomer({ ...customer, declaration: event.target.checked })}>
          I declare that I am authorised to act on behalf of the entity above.
        </Checkbox>
      )}
      {customerType && (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            disabled={!customer.declaration || submited}
            type='primary'
            onClick={onSubmit}
            ghost>
            Submit
          </Button>
        </Col>
      )}
    </Fragment>
  );
};

export default CreateCustomer;
