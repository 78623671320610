import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { store } from '../context/AppContext';
import getAllEnergySystems from './helpers/getAllEnergySystems';
import { filterParticipants } from './helpers/filterParticipants';
import ParticipantsTable from './components/ParticipantsTable';

export default function ParticipantsScreen() {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [localState, setLocalState] = useState({
    participants: [],
    energySystems: [],
    participantsLoading: false,
    energySystemsLoading: false,
  });

  useEffect(() => {
    if (state.customerId && !localState.energySystemsLoading) energySystemsStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.customerId]);

  useEffect(() => {
    if (
      !localState.energySystemsLoading &&
      !localState.participants.length &&
      !localState.participantsLoading
    )
      participantsStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.energySystems]);

  if (!state?.groupMaster && !state?.groupBlind) return <Redirect to="/dashboard" />;

  async function energySystemsStartup() {
    setLocalState({ ...localState, energySystemsLoading: true });
    const response = await getAllEnergySystems(state.customerId);

    if (response.code === 200) {
      setLocalState({
        ...localState,
        energySystems: JSON.parse(response.energySystems),
        energySystemsLoading: false,
      });
    } else {
      setLocalState({ ...localState, energySystemsLoading: false });
    }
  }

  async function participantsStartup() {
    setLocalState({ ...localState, participantsLoading: true });
    const response = await filterParticipants(
      state.customerId,
      state.customerGroups,
      localState.energySystems,
      dispatch
    );

    setLocalState({ ...localState, participants: response, participantsLoading: false });
  }

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100%',
        padding: '12px 14px',
        overflow: 'scroll',
      }}
    >
      <ParticipantsTable
        participants={localState.participants}
        loading={localState.participantsLoading || localState.energySystemsLoading}
      />
    </div>
  );
}
