import React, { Fragment, useEffect, useState } from 'react';
import {
  VictoryBar,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryLabel,
} from 'victory';
import { Row } from 'antd';
import { formatLabel, setXTickValue } from '../helpers/chartLabels';
import dataToGraph from '../helpers/dataToGraph';
import shortDataToGraph from '../helpers/shortDataToGraph';
import setInverted from '../helpers/setInverted';
import latestTimestamp from '../helpers/latestTimestamp';

const Graph = (props) => {
  const { load, period, energySystemId, display } = props;
  const [footerLoaded, setFooterLoaded] = useState(false);

  // ? 35 : 40;

  const mobile = window.innerWidth < 768;
  let flyoutWidth = 0;
  let flyoutHeight = 0;

  if (display === 'emissionsData') {
    flyoutWidth = mobile ? 160 : 210;
    flyoutHeight = mobile ? 45 : 55;
  } else {
    flyoutWidth = mobile ? 150 : 200;
    flyoutHeight = mobile ? 30 : 35;
  }

  useEffect(() => {
    setFooterLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('dashboard-footer')]);

  let graphData;

  let energyData = props.energyData[energySystemId].shortEnergy;

  let inverted = setInverted(energySystemId, props.load, props.energySystems);

  if (period === 'live') {
    if (energyData && !!energyData.length)
      graphData = shortDataToGraph(
        props.energyData,
        energySystemId,
        load,
        display,
        energyData[0].duration,
        inverted,
        props.energySystems
      );
    else graphData = [];
  } else {
    const energyAccumulator = props.energyAccumulator[energySystemId];
    let latestEnergyTimestamp = latestTimestamp(energyAccumulator.minute);

    graphData = dataToGraph(
      props.energyAccumulator,
      energySystemId,
      period,
      load,
      display,
      inverted,
      latestEnergyTimestamp,
      props.energySystems
    );
  }

  if (!mobile && !footerLoaded) return <Fragment />;

  const yTickText =
    display === 'energyData' ? (period === 'live' ? '(kW)' : '(kWh)') : display === 'dollarData' ? '($)' : '(kgCO2e)';

  // console.log('Graph Data');
  // console.log(graphData);

  if (period === 'month') {
    const gap = 5;
    let counter = 0;

    return (
      <Row
        align="middle"
        justify="start"
        style={{
          padding: 0,
          marginLeft: mobile ? '-8%' : 0,
          marginTop: mobile ? '-5%' : 0,
          width: mobile ? '110%' : '100%',
        }}
      >
        <VictoryChart
          width={window.innerWidth * 1.02}
          height={window.innerHeight - 64 - (document.getElementById('dashboard-footer')?.clientHeight || 0)}
          domainPadding={{ y: [0, 0], x: [0, window.innerWidth * 0.01] }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => datum.label}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutWidth={flyoutWidth}
                  flyoutHeight={flyoutHeight}
                  cornerRadius={4}
                  flyoutStyle={{ fill: 'white', stroke: 'white', strokeWidth: 2 }}
                  style={{ fill: '#1890ff', fontSize: mobile ? 11 : 13 }}
                />
              }
              style={{
                touchAction: 'auto',
              }}
            />
          }
        >
          <VictoryLabel
            text={yTickText}
            x={mobile ? window.innerWidth * 0.89 : window.innerWidth * 0.99}
            y={30}
            textAnchor="start"
            style={{ fill: 'white', fontSize: mobile ? 11 : 13 }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'transparent' },
              axisLabel: { fontSize: mobile ? 11 : 13 },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
              fontFamily: 'inherit',
            }}
            tickValues={Array.from(Array(graphData.length).keys())}
            tickFormat={(t) => {
              if (mobile) {
                if (counter === gap) {
                  counter = 0;
                  return t;
                }

                counter += 1;
                return '';
              }

              return t;
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => t}
            orientation="right"
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
            }}
          />

          <VictoryBar
            data={graphData}
            cornerRadius={{ top: 5, bottom: 5 }}
            height="100%"
            width="100%"
            barWidth={window.innerWidth * 0.025}
            domainPadding={{ x: 0, y: 0 }}
            padding={0}
            style={{
              data: { fill: '#fff', stroke: '#fff', strokeWidth: 3, strokeOpacity: 0.15 },
              labels: { fill: 'transparent' },
            }}
            x="time"
            y="amount"
          />
        </VictoryChart>
      </Row>
    );
  } else if (period === 'year') {
    const monthSymbols = {
      1: 'J',
      2: 'F',
      3: 'M',
      4: 'A',
      5: 'M',
      6: 'J',
      7: 'J',
      8: 'A',
      9: 'S',
      10: 'O',
      11: 'N',
      12: 'D',
    };

    return (
      <Row
        align="middle"
        justify="start"
        style={{
          padding: 0,
          marginLeft: mobile ? '-2%' : 0,
          marginTop: mobile ? '-5%' : 0,
          width: mobile ? '110%' : '100%',
        }}
      >
        <VictoryChart
          width={window.innerWidth * 1.02}
          height={window.innerHeight - 64 - (document.getElementById('dashboard-footer')?.clientHeight || 0)}
          domainPadding={{
            y: [window.innerHeight * 0.001, window.innerHeight * 0.001],
            x: [mobile ? 0 : window.innerWidth * 0.01, window.innerWidth * 0.035],
          }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => datum.label}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutWidth={flyoutWidth}
                  flyoutHeight={flyoutHeight}
                  cornerRadius={4}
                  flyoutStyle={{ fill: 'white', stroke: 'white', strokeWidth: 2 }}
                  style={{ fill: '#1890ff', fontSize: mobile ? 11 : 13 }}
                />
              }
              style={{
                touchAction: 'auto',
              }}
            />
          }
        >
          <VictoryLabel
            text={yTickText}
            x={mobile ? window.innerWidth * 0.89 : window.innerWidth * 0.99}
            y={30}
            textAnchor="start"
            style={{ fill: 'white', fontSize: mobile ? 11 : 13 }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'transparent' },
              axisLabel: { fontSize: mobile ? 11 : 13 },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
              fontFamily: 'inherit',
            }}
            tickValues={Array.from(Array(graphData.length).keys())}
            tickFormat={(t) => (mobile ? monthSymbols[t.split('/')[0]] : t)}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => t}
            orientation="right"
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
            }}
          />

          <VictoryBar
            data={graphData}
            cornerRadius={{ top: 5, bottom: 5 }}
            height="100%"
            width="100%"
            barWidth={mobile ? window.innerWidth * 0.05 : window.innerWidth * 0.07}
            padding={0}
            domainPadding={{ x: 0, y: 0 }}
            style={{
              data: { fill: '#fff', stroke: '#fff', strokeWidth: 3, strokeOpacity: 0.15 },
              labels: { fill: 'transparent' },
            }}
            x="time"
            y="amount"
          />
        </VictoryChart>
      </Row>
    );
  } else {
    return (
      <Row
        align="middle"
        justify="space-around"
        style={{
          padding: 0,
          marginLeft: mobile ? '-10%' : 0,
          marginTop: mobile ? '-5%' : 0,
          width: mobile ? '110%' : '100%',
        }}
      >
        <VictoryChart
          width={window.innerWidth * 1.02}
          height={window.innerHeight - 64 - (document.getElementById('dashboard-footer')?.clientHeight || 0)}
          domainPadding={{ y: [25, 25], x: [0, 0] }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => formatLabel(display, datum, period)}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutWidth={flyoutWidth}
                  flyoutHeight={flyoutHeight}
                  cornerRadius={4}
                  flyoutStyle={{ fill: 'white', stroke: 'white', strokeWidth: 2 }}
                  style={{ fill: '#1890ff', fontSize: mobile ? 11 : 13 }}
                />
              }
              style={{
                touchAction: 'auto',
              }}
            />
          }
        >
          <VictoryLabel
            text={yTickText}
            x={mobile ? window.innerWidth * 0.89 : window.innerWidth * 0.99}
            y={30}
            textAnchor="start"
            style={{ fill: 'white', fontSize: mobile ? 11 : 13 }}
          />
          <VictoryAxis
            tickValues={Array.from(Array(graphData.length).keys())}
            tickFormat={(t) => setXTickValue(graphData, period, t)}
            style={{
              axis: { stroke: 'transparent' },
              axisLabel: { fontSize: mobile ? 11 : 13, padding: 0, margin: 0, height: 0 },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="right"
            tickFormat={(t) => t}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: { fontSize: mobile ? 11 : 13, fill: '#E0F2F1', fontFamily: 'inherit' },
            }}
          />
          <VictoryLine
            style={{
              data: { stroke: '#f1f2f6' },
            }}
            padding={0}
            domainPadding={{ x: 0, y: 0 }}
            samples={graphData.length}
            data={graphData}
            interpolation={'natural'}
            height="100%"
            width="100%"
            x="time"
            y="amount"
          />
        </VictoryChart>
      </Row>
    );
  }
};

export default Graph;
