import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import { Spin, Alert, Row, Col, List, Collapse, Badge, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { store } from '../context/AppContext';
import createUser from '../user/helper/createUser';
import getUser from '../user/helper/getUser';
import getCustomers from '../customer/helpers/getCustomers';
import getInvites from '../invites/helpers/getInvites';
import './CustomerScreen.css';
import CreateCustomer from './components/CreateCustomer';
import CustomerItem from './components/CustomerItem';
import logo_text from '../images/logo_text.png';

const { Panel } = Collapse;

const CustomerScreen = (props) => {
  const context = useContext(store);
  const { state, dispatch } = context;
  const [customers, setCustomers] = useState(null);
  const [invites, setInvites] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [activeKey, setActiveKey] = useState(0);
  const cognitoUser = localStorage.getItem('cognitoUser');
  const history = useHistory();

  useEffect(() => {
    if (props.location.search) {
      let query = qs.parse(props.location.search.substring(1));

      if (query.switch) dispatch({ type: 'SWITCH_CUSTOMER' });
    }

    if (state.customerId) {
      if (state.groupMaster || state.groupBlind) {
        console.log("UseEffect1")
        return history.push('/analytics')
      };

      return history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.customerId) {
      if (state.groupMaster || state.groupBlind) {
        console.log("UseEffect2")
        return history.push('/analytics')
      };

      return history.push('/');
    }

    if (cognitoUser && cognitoUser !== 'undefined' && !fetched) {
      setFetched(true);
      getUserAndCustomers();
      dispatch({ type: 'USER', data: cognitoUser });
    }
  }, [
    state.customerId,
    state.groupMaster,
    state.groupBlind,
    cognitoUser,
    fetched,
    history,
    dispatch,
  ]);

  const getUserAndCustomers = async () => {
    let userResponse = await getUser();
    if (userResponse.code === 404) userResponse = await createUser();

    if (userResponse.code === 200) {
      const user = JSON.parse(userResponse.user);
      const invitesResponse = await getInvites();
      let customerResponse;
      let responseCustomers = [];

      if (!user.customers.length) {
        await setCustomers([]);
      } else {
        customerResponse = await getCustomers(user.customers);

        if (customerResponse.code === 200) {
          responseCustomers = JSON.parse(customerResponse.customers);

          await setCustomers(responseCustomers);
          // customersClone = responseCustomers;
        }
      }

      if (invitesResponse.code === 200) {
        let invites = JSON.parse(invitesResponse.invites);
        invites = invites.filter((invite) => invite.active === true);
        const customerIds = invites.map((invite) => invite.customerId);

        if (customerIds.constructor === Array && !!customerIds.length) {
          customerResponse = await getCustomers(customerIds);

          if (customerResponse.code === 200) {
            let inviteCustomers = JSON.parse(customerResponse.customers);
            inviteCustomers = inviteCustomers.filter(
              (invite) => !responseCustomers.map((customer) => customer.id).includes(invite.id)
            );
            setInvites(inviteCustomers);
            // invitesClone = inviteCustomers;
          }
        } else {
          setInvites([]);
        }
      } else {
        await setInvites([]);
      }
    }

    // Can move this earlier to reduce blocking
    setLoading(false);
  };

  let customerContainer = <Spin />;
  if (!loading) {
    customerContainer = (
      <Row justify='center' style={{ width: '320px' }}>
        {customers && !customers.length && (
          <Col span={24}>
            <Alert
              message='No customer configured for this account.'
              type='warning'
              showIcon
              closable
            />
          </Col>
        )}
        <Col span={12} style={{ textAlign: 'center' }}>
          <Button
            onClick={() => setActiveKey(1)}
            type={activeKey === 1 ? 'primary' : 'default'}
            ghost={activeKey === 1 ? true : false}>
            Create customer
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Button
            onClick={() => setActiveKey(2)}
            type={activeKey === 2 ? 'primary' : 'default'}
            ghost={activeKey === 2 ? true : false}>
            Request access
          </Button>
        </Col>
        {activeKey === 1 && <CreateCustomer />}
        {activeKey === 2 && <span style={{ marginTop: 20 }}>Coming soon.</span>}
      </Row>
    );
  }

  const defaultOption = customers?.length ? '2' : '1';

  return (
    <div className='accounts-list-container'>
      <Helmet>
        <title>Accounts</title>
      </Helmet>
      <Row justify='center' className='logo'>
        <img className='logo-image' src={logo_text} alt='fluxpower_logo' />
      </Row>
      {customerContainer}
      {!loading && (
        <Collapse
          defaultActiveKey={[defaultOption]}
          accordion
          ghost
          expandIconPosition='right'
          style={{ width: 320 }}>
          {invites?.length && (
            <Panel
              header={
                <Badge
                  size='small'
                  count={invites.length}
                  offset={[10, 0]}
                  style={{ fontSize: 11 }}>
                  <span>Invites</span>
                </Badge>
              }
              key='1'>
              <List
                className='list'
                itemLayout='horizontal'
                dataSource={invites}
                renderItem={(invite) => (
                  <CustomerItem
                    type='invite'
                    customers={customers.map((customer) => customer.id)}
                    customer={invite}
                  />
                )}
              />
            </Panel>
          )}
          {customers?.length && (
            <Panel header='Customers' key='2'>
              <List
                className='list'
                itemLayout='horizontal'
                dataSource={customers}
                renderItem={(customer) => <CustomerItem type='login' customer={customer} />}
              />
            </Panel>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default CustomerScreen;
