import { API } from 'aws-amplify';

export default async function getEnergyAccumulator(energySystems) {
  let accumulator = {};

  for (let energySystem of energySystems) {
    const promise = new Promise((resolve, reject) => {
      const apiName = 'energyaccumulatorapi';
      const path = `/energyaccumulator?device_id=${energySystem.identifierNumber}&env=${process.env.REACT_APP_ENV}`;

      API.get(apiName, path)
        .then((response) => resolve(response))
        .catch((error) => {
          console.log('error', error);
        });
    });

    const response = await promise;

    if (response.code === 200) accumulator[energySystem.identifierNumber] = response.energyAccumulator;
  }

  return accumulator;
}
