import { API } from 'aws-amplify';

export default async function getDataToExport(exportType, deviceId, startDate, endDate, granularity) {
    const promise = new Promise((resolve, reject) => {
        const apiName = 'exportdataapi';
        const path = `/export?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&granularity=${granularity}&exportType=${exportType}`;

        API
          .get(apiName, path)
          .then(response => resolve(response))
          .catch(error => {
            console.log('error', error);
        });
    });

    return await promise;
}
