import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tag } from 'antd';
import { formatDistance } from 'date-fns';
import { DashboardOutlined } from '@ant-design/icons';
import getParticipantsByGroup from './getParticipantsByGroup';

export async function filterParticipants(customerId, groups, energySystems, dispatch, siteFilter = false) {
    let participants = [];

    for (let group of groups) {
        const response = await getParticipantsByGroup(customerId, group.groupName);

        if (response.code === 200) {
            const customers = JSON.parse(response.customers);

            if ((group.groupType === 'Master' || group.groupType === 'Blind' || siteFilter) && customers) {
                let matchingCustomers = customers.filter((customer) =>
                    customer.groups && customer.groups.some((g) =>
                        g.groupName === group.groupName && g.groupType === 'Participant'
                    )
                );

                matchingCustomers = await formatSites(matchingCustomers, dispatch, energySystems);

                if (group.groupType === 'Blind') {
                    for (let matchingCustomer of matchingCustomers) {
                        matchingCustomer.siteName = '';
                        matchingCustomer.legalName = '';
                        matchingCustomer.abn = '';
                    }
                }

                participants = participants.concat(matchingCustomers);
            }
        }
    }

    return participants;
}

export async function formatSites(matchingCustomers, dispatch, energySystems) {
    for (let matchingCustomer of matchingCustomers) {
        matchingCustomer.dashboard = <NavLink to="/dashboard">
            <DashboardOutlined onClick={() => dispatch({type: "DASHBOARD", data: matchingCustomer.id}) } />
        </NavLink>;

        const customerEnergySystems = energySystems.filter((energySystem) => energySystem.customerId === matchingCustomer.id);

        matchingCustomer.exportLinks = [];
        matchingCustomer.deviceStatus = [];

        for (let customerEnergySystem of customerEnergySystems) {
            let icon;

            matchingCustomer.exportLinks.push(
                <p key={customerEnergySystem.identifierNumber} style={{margin: "7px 0"}}>
                    <NavLink
                        to="/export"
                        onClick={() => dispatch({type: "DASHBOARD", data: matchingCustomer.id})}
                    >
                        <span style={{marginRight: 5}}>{customerEnergySystem.identifierNumber}</span>
                    </NavLink>
                    {/* <a target="_blank" href={`https://docs.google.com/spreadsheets/d/${customerEnergySystem.sheetId}`}> */}
                        {/* <Icon type="export" /> */}
                        {/* <span style={{marginRight: 5}}>{customerEnergySystem.identifierNumber}</span> */}
                    {/* </a> */}
                </p>
            );

            if (customerEnergySystem.lastTimestamp >= Math.floor((Date.now() / 1000) - 60*15))
                icon = <Tag color="green">Online</Tag>
            else if (customerEnergySystem.lastTimestamp >= Math.floor((Date.now() / 1000) - 60*120))
                icon = <Tag color="orange">Delayed - {formatDistance(customerEnergySystem.lastTimestamp * 1000, Date.now())}</Tag>
            else
                icon = <Tag color="red">Offline - {formatDistance(customerEnergySystem.lastTimestamp * 1000, Date.now())}</Tag>

            matchingCustomer.deviceStatus.push(
                <p key={customerEnergySystem.identifierNumber + '-icon'} style={{margin: "7px 0"}}>
                    {icon}
                </p>
            );
        }
    }

    return matchingCustomers;
}
