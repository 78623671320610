import React from "react";
import {Row} from "antd";

export default function MessageScreen(props) {
    return (
        <Row style={{height: "100vh", width: "100%"}}>
            <Row
                align="middle"
                justify="space-around"
                style={{width: "100%", height: "calc(100% - 64px)", color: "#fff", backgroundColor: "rgba(0,0,0,0.1)"}}
            >
                <span>{props.message}</span>
            </Row>
        </Row>
    );
}
