import { formatMonthLabel } from './chartLabels';

// emissions_type is 'grid' or 'solar'
export function GetEnergySystemIdAndLabel(energySystems, emissions_type) {
  let energySystemId = null;
  let label = null;

  for (let system of energySystems) {
    for (let item of system.deviceMap) {
      if (item.emissions_type === emissions_type) {
        label = `${item.label}`;
        energySystemId = system.identifierNumber;
        return [energySystemId, label];
      }
    }
  }

  return [energySystemId, label];
}

export default function dataToGraph(
  energyAccumulators,
  energySystemId,
  period,
  category,
  option,
  inverted,
  latestEnergyTimestamp,
  energySystems
) {
  let energyAccumulator = energyAccumulators[energySystemId];

  console.log('Energy Accumulators');
  console.log(energyAccumulators);

  let firstTimestamp;
  let values = [];

  let [grid_system_id, grid_category] = GetEnergySystemIdAndLabel(energySystems, 'grid');
  let [solar_system_id, solar_category] = GetEnergySystemIdAndLabel(energySystems, 'solar');

  // console.log('Grid System Id, ', grid_system_id);
  // console.log('Grid Category, ', grid_category);
  // console.log('Solar System Id, ', solar_system_id);
  // console.log('Solar Category, ', solar_category);

  let selectedLoad = category.split('_');
  selectedLoad.shift();
  selectedLoad = selectedLoad.join('_');

  let selected_load_emissions_type = 'grid';
  for (let system of energySystems) {
    for (let device of system.deviceMap) {
      if (device.label === selectedLoad) {
        selected_load_emissions_type = device.emissions_type;
      }
    }
  }

  switch (period) {
    case 'day': {
      firstTimestamp = latestEnergyTimestamp - (288 - 1) * 300;
      let dayAccumulator = 0;
      let abatedAccumulator = 0;

      const lastDay = new Date(latestEnergyTimestamp * 1000);
      lastDay.setHours(0, 0, 0, 0);

      const minuteId = Math.floor((latestEnergyTimestamp * 1000 - lastDay.getTime()) / 300000);

      let finalMinuteAccumulator;
      if (energyAccumulator.minute) {
        const orderedAccumulator = energyAccumulator.minute.sort((a, b) => (a.id > b.id ? 1 : -1));
        finalMinuteAccumulator = orderedAccumulator
          .slice(minuteId + 2, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, minuteId + 2));
      } else return [];

      console.log(`Length: `, finalMinuteAccumulator.length);

      for (let i = 0; i < finalMinuteAccumulator.length; i++) {
        const minuteAccumulator = finalMinuteAccumulator[i];

        let energyValue = 0;

        if (minuteAccumulator) {
          let energy = minuteAccumulator[option].find((item) => energySystemId + '_' + item.label === category);
          energyValue = energy && energy.value ? (inverted ? energy.value * -1 : energy.value) : 0;
          energyValue = Math.abs(energyValue);
        }

        dayAccumulator += energyValue;

        let abated = GenerateAbated(
          energyAccumulators,
          'minute',
          null,
          grid_category,
          solar_category,
          grid_system_id,
          solar_system_id,
          latestEnergyTimestamp,
          energySystemId,
          finalMinuteAccumulator,
          i
        );

        abated = Math.round(abated * 100) / 100;
        abatedAccumulator += abated;
        let roundedAbatedAccumulator = Math.round(abatedAccumulator * 100) / 100;

        if (selected_load_emissions_type === 'solar') {
          values.push({
            time: i,
            amount: abated,
            timestamp: firstTimestamp + i * 300,
            accumulator: dayAccumulator,
            abated: solar_category ? abated : null,
            abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
          });
        } else {
          values.push({
            time: i,
            amount: energyValue,
            timestamp: firstTimestamp + i * 300,
            accumulator: dayAccumulator,
            abated: solar_category ? abated : null,
            abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
          });
        }
      }

      return values;
    }
    case 'week': {
      firstTimestamp = latestEnergyTimestamp - (2016 - 1) * 300;
      let weekAccumulator = 0;
      let abatedAccumulator = 0;

      const latestSunday = new Date(latestEnergyTimestamp * 1000);
      latestSunday.setDate(latestSunday.getDate() - latestSunday.getDay());
      latestSunday.setHours(0, 0, 0, 0);

      const hourId = Math.floor((latestEnergyTimestamp * 1000 - latestSunday.getTime()) / 3600000);

      let finalHourAccumulator;
      if (energyAccumulator.hour) {
        const orderedAccumulator = energyAccumulator.hour.sort((a, b) => (a.id > b.id ? 1 : -1));
        finalHourAccumulator = orderedAccumulator
          .slice(hourId + 1, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, hourId + 1));
      } else return [];

      for (let i = 0; i < finalHourAccumulator.length; i++) {
        const hourAccumulator = finalHourAccumulator[i];
        let energyValue = 0;
        // console.log(category);

        if (hourAccumulator) {
          let energy = hourAccumulator[option].find((item) => energySystemId + '_' + item.label === category);
          // console.log(energy);
          energyValue = energy && energy.value ? (inverted ? energy.value * -1 : energy.value) : 0;
          energyValue = Math.abs(energyValue);
        }
        weekAccumulator += energyValue;

        let abated = GenerateAbated(
          energyAccumulators,
          'hour',
          null,
          grid_category,
          solar_category,
          grid_system_id,
          solar_system_id,
          latestEnergyTimestamp,
          energySystemId,
          finalHourAccumulator,
          i
        );
        abatedAccumulator += abated;
        abated = Math.round(abated * 100) / 100;

        if (isNaN(abated)) {
          abated = 0;
        }
        let roundedAbatedAccumulator = Math.round(abatedAccumulator * 100) / 100;

        if (selected_load_emissions_type === 'solar') {
          values.push({
            time: i,
            amount: abated,
            timestamp: firstTimestamp + i * 3600,
            accumulator: weekAccumulator,
            abated: solar_category ? abated : null,
            abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
          });
        } else {
          values.push({
            time: i,
            amount: energyValue,
            timestamp: firstTimestamp + i * 3600,
            accumulator: weekAccumulator,
            abated: solar_category ? abated : null,
            abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
          });
        }
      }

      return values;
    }
    case 'month': {
      let monthAccumulator = 0;
      let abatedAccumulator = 0;

      for (let i = 0; i < 30; i++) {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - (29 - i));

        const time = `${currentDate.getDate().toString()}/${(currentDate.getMonth() + 1).toString()}`;

        const timeString = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

        const dayAccumulator = energyAccumulator.day.find((dayRecord) => dayRecord.date === timeString);
        let energyValue = 0;

        if (dayAccumulator) {
          let energy = dayAccumulator[option].find((item) => energySystemId + '_' + item.label === category);
          energyValue = energy && energy.value ? (inverted ? energy.value * -1 : energy.value) : 0;
          energyValue = Math.abs(energyValue);
        }

        console.log('Energy Value of Month', monthAccumulator);

        monthAccumulator += energyValue;

        // console.log('Grid Category: ', grid_category);
        // console.log('Category: ', category);

        // console.log('Option: ', option);

        // console.log('Day Accumulaor');
        // console.log(dayAccumulator);
        // // console.log(
        // //   dayAccumulator[option].find((item) => {
        // //     console.log('item');
        // //     console.log(item);
        // //     return energySystemId + '_' + item.label === grid_category;
        // //   })
        // // );

        let abated = GenerateAbated(
          energyAccumulators,
          'day',
          timeString,
          grid_category,
          solar_category,
          grid_system_id,
          solar_system_id,
          latestEnergyTimestamp,
          energySystemId,
          null
        );

        // Using option, add data to the values tab

        abated = Math.round(abated * 100) / 100;
        abatedAccumulator += abated;
        let roundedAbatedAccumulator = Math.round(abatedAccumulator * 100) / 100;

        if (selected_load_emissions_type === 'solar') {
          values.push({
            time: time,
            amount: abated,
            label: formatMonthLabel(option, {
              amount: abated,
              time: time,
              accumulator: monthAccumulator,
              abated: solar_category ? abated : null,
              abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
            }),
          });
        } else {
          values.push({
            time: time,
            amount: energyValue,
            label: formatMonthLabel(option, {
              amount: energyValue,
              time: time,
              accumulator: monthAccumulator,
              abated: solar_category ? abated : null,
              abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
            }),
          });
        }
      }

      console.log('VALUES');
      console.log(values);

      return values;
    }
    case 'year': {
      let yearAccumulator = 0;
      let abatedAccumulator = 0;

      for (let i = 0; i < 12; i++) {
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - (11 - i));

        const time = `${(currentDate.getMonth() + 1).toString()}/${currentDate.getFullYear().toString()}`;

        let timeString = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;

        const monthAccumulator = energyAccumulator.month.find((monthRecord) => monthRecord.date === timeString);
        let energyValue = 0;

        if (monthAccumulator) {
          let energy = monthAccumulator[option].find((item) => energySystemId + '_' + item.label === category);
          energyValue = energy && energy.value ? (inverted ? energy.value * -1 : energy.value) : 0;
          energyValue = Math.abs(energyValue);
        }
        yearAccumulator += energyValue;

        let abated = GenerateAbated(
          energyAccumulators,
          'month',
          timeString,
          grid_category,
          solar_category,
          grid_system_id,
          solar_system_id,
          latestEnergyTimestamp,
          energySystemId,
          null
        );

        abated = Math.round(abated * 100) / 100;
        abatedAccumulator += abated;
        let roundedAbatedAccumulator = Math.round(abatedAccumulator * 100) / 100;

        if (selected_load_emissions_type === 'solar') {
          values.push({
            time: time,
            amount: abated,
            label: formatMonthLabel(option, {
              amount: abated,
              time: time,
              accumulator: yearAccumulator,
              abated: solar_category ? abated : null,
              abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
            }),
          });
        } else {
          values.push({
            time: time,
            amount: energyValue,
            label: formatMonthLabel(option, {
              amount: energyValue,
              time: time,
              accumulator: yearAccumulator,
              abated: solar_category ? abated : null,
              abatedAccumulator: solar_category ? roundedAbatedAccumulator : null,
            }),
          });
        }
      }

      return values;
    }
    default: {
      return [];
    }
  }
}

// Type month
// Time

function GenerateAbated(
  energyAccumulators,
  accumulator_type,
  timeString,
  grid_category,
  solar_category,
  grid_system_id,
  solar_system_id,
  latestEnergyTimestamp,
  energySystemId,
  finalAccumulator,
  index
) {
  let emissions_index = 0;
  let energy_use = 0;
  let solar_energy_use = 0;
  let abated = 0;

  if (!solar_category) {
    return 0;
  }

  let gridAccumulator = null;
  let solarAccumulator = null;

  if (accumulator_type === 'minute') {
    if (grid_system_id === energySystemId) {
      gridAccumulator = finalAccumulator[index];
    } else {
      const lastDay = new Date(latestEnergyTimestamp * 1000);
      lastDay.setHours(0, 0, 0, 0);

      const minuteId = Math.floor((latestEnergyTimestamp * 1000 - lastDay.getTime()) / 300000);

      let finalMinuteGridAccumulator;

      let tempAccumulator = energyAccumulators[grid_system_id];

      if (tempAccumulator[accumulator_type]) {
        const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
        finalMinuteGridAccumulator = orderedAccumulator
          .slice(minuteId + 2, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, minuteId + 2));
      } else {
        finalMinuteGridAccumulator = [];
      }

      gridAccumulator = finalMinuteGridAccumulator[index];
    }

    if (solar_system_id === energySystemId) {
      solarAccumulator = finalAccumulator[index];
    } else {
      const lastDay = new Date(latestEnergyTimestamp * 1000);
      lastDay.setHours(0, 0, 0, 0);

      const minuteId = Math.floor((latestEnergyTimestamp * 1000 - lastDay.getTime()) / 300000);

      let finalMinuteSolarAccumulator;

      let tempAccumulator = energyAccumulators[solar_system_id];

      if (tempAccumulator[accumulator_type]) {
        const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
        finalMinuteSolarAccumulator = orderedAccumulator
          .slice(minuteId + 2, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, minuteId + 2));
      } else {
        finalMinuteSolarAccumulator = [];
      }

      solarAccumulator = finalMinuteSolarAccumulator[index];
    }
  } else if (accumulator_type === 'hour') {
    if (grid_system_id === energySystemId) {
      gridAccumulator = finalAccumulator[index];
    } else {
      const latestSunday = new Date(latestEnergyTimestamp * 1000);
      latestSunday.setDate(latestSunday.getDate() - latestSunday.getDay());
      latestSunday.setHours(0, 0, 0, 0);

      const hourId = Math.floor((latestEnergyTimestamp * 1000 - latestSunday.getTime()) / 3600000);

      let finalHourGridAccumulator;

      let tempAccumulator = energyAccumulators[grid_system_id];
      if (tempAccumulator[accumulator_type]) {
        const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
        finalHourGridAccumulator = orderedAccumulator
          .slice(hourId + 1, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, hourId + 1));
      } else {
        finalHourGridAccumulator = [];
      }

      // console.log('Temp Accumulator');
      // console.log(tempAccumulator);

      gridAccumulator = finalHourGridAccumulator[index];
      // console.log('Grid Accumulator');
      // console.log(gridAccumulator);
    }

    if (solar_system_id === energySystemId) {
      solarAccumulator = finalAccumulator[index];
    } else {
      const latestSunday = new Date(latestEnergyTimestamp * 1000);
      latestSunday.setDate(latestSunday.getDate() - latestSunday.getDay());
      latestSunday.setHours(0, 0, 0, 0);

      const hourId = Math.floor((latestEnergyTimestamp * 1000 - latestSunday.getTime()) / 3600000);

      let finalHourSolarAccumulator;

      let tempAccumulator = energyAccumulators[grid_system_id];
      if (tempAccumulator[accumulator_type]) {
        const orderedAccumulator = tempAccumulator[accumulator_type].sort((a, b) => (a.id > b.id ? 1 : -1));
        finalHourSolarAccumulator = orderedAccumulator
          .slice(hourId + 1, orderedAccumulator.length)
          .concat(orderedAccumulator.slice(0, hourId + 1));
      } else {
        finalHourSolarAccumulator = [];
      }

      // console.log('Temp Accumulator');
      // console.log(tempAccumulator);

      solarAccumulator = finalHourSolarAccumulator[index];

      // console.log('Grid Accumulator');
      // console.log(solarAccumulator);
    }
  } else {
    gridAccumulator = energyAccumulators[grid_system_id][accumulator_type].find((record) => record.date === timeString);
    solarAccumulator = energyAccumulators[solar_system_id][accumulator_type].find(
      (record) => record.date === timeString
    );
  }

  // console.log('Grid Accumulator');
  // console.log(gridAccumulator);
  // console.log('Solar Accumulator');
  // console.log(solarAccumulator);

  // console.log('Solar Category: ', solar_category);

  if (gridAccumulator && solarAccumulator) {
    energy_use = gridAccumulator['energyData'].find((item) => item.label === grid_category)?.value;

    let emissions_data = gridAccumulator['emissionsData'].find((item) => {
      // console.log('Item Label');
      // console.log(item.label);

      // console.log("Grid_category");
      // console.log(grid_category)
      return item.label === grid_category;
    })?.value;

    // console.log('Energy Use');
    // console.log(energy_use);

    // console.log('Emissions_data');
    // console.log(emissions_data);

    if (!energy_use || energy_use === 0) {
      emissions_index = 0;
    } else {
      emissions_index = Math.abs(emissions_data / energy_use);
    }

    solar_energy_use = solarAccumulator['energyData'].find((item) => item.label === solar_category)?.value;

    // console.log('Solar Energy Use');
    // console.log(solar_energy_use);

    // console.log('Emissions_index');
    // console.log(emissions_index);

    if (solar_energy_use) {
      abated = Math.abs(solar_energy_use) * emissions_index;
    }
  }
  // console.log('Abated: ', abated);

  return abated;
}
